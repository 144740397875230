export default {
  isClient(state) {
    return state.role === 'client'
  },

  isStudent(state) {
    return state.role === 'student'
  },

  isAuthorized(state) {
    return state.role !== ''
  },

  isDemoUser(state) {
    return (
      process.iblsEnv.IBLS_DEMO_USER_EMAILS?.split(',').includes(state.email) ||
      state.isDemo
    )
  },
}
