export const supportRoutes = [
  {
    path: 'support',
    name: 'student-support',
    component: () =>
      import(
        /* webpackChunkName: "student-support" */ '@frontend/pages/student/support/Support.vue'
      ),

    children: [
      {
        path: 'questions',
        name: 'student-support-questions',
        component: () =>
          import(
            /* webpackChunkName: "student-support-questions" */ '@frontend/pages/student/support/Questions.vue'
          ),
      },

      {
        path: 'ideas',
        name: 'student-support-ideas',
        component: () =>
          import(
            /* webpackChunkName: "student-support-ideas" */ '@frontend/pages/student/support/Ideas.vue'
          ),
      },

      {
        path: 'faq',
        name: 'student-support-faq',
        component: () =>
          import(
            /* webpackChunkName: "student-support-faq" */ '@frontend/pages/student/support/FAQ.vue'
          ),
      },

      {
        path: 'contacts',
        name: 'student-support-contacts',
        component: () =>
          import(
            /* webpackChunkName: "student-support-contacts" */ '@frontend/pages/student/support/Contacts.vue'
          ),
      },
    ],
  },

  {
    path: 'support/questions/:questionId(\\d+)',
    name: 'student-support-the-question',
    component: () =>
      import(
        /* webpackChunkName: "student-support-the-question" */ '@frontend/pages/student/support/TheQuestion.vue'
      ),
  },

  {
    path: 'support/ideas/:ideaId(\\d+)',
    name: 'student-support-the-idea',
    component: () =>
      import(
        /* webpackChunkName: "student-support-the-idea" */ '@frontend/pages/student/support/TheIdea.vue'
      ),
  },
]
