<template>
  <div id="app">
    <transition-group name="fade-layout" mode="out-in">
      <router-view v-if="!isLoading" key="main" />
      <LoaderFullScreen v-else key="loader" />
    </transition-group>
  </div>
</template>

<script>
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import FontFaceObserver from 'fontfaceobserver'
import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    LoaderFullScreen,
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  computed: {
    ...mapState(['isGlobalLoading']),

    isLoading() {
      return this.isGlobalLoading || this.isLoadingFonts
    },
  },

  created() {
    this.setInitialData()
  },

  methods: {
    setInitialData() {
      this.initFonts()
    },

    initFonts() {
      document.fonts.ready.then(() => {
        const fontawesome = new FontFaceObserver('Font Awesome 5 Free')

        fontawesome.load().then(() => {
          this.isLoadingFonts = false
        })
      })
    },
  },

  metaInfo: {
    titleTemplate: (titleChunk) =>
      titleChunk ? `${titleChunk} | IBLS` : 'IBLS',
  },
}
</script>

<style lang="scss">
@import '~@frontend/app.scss';
</style>
