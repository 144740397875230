export default () => ({
  email: '',
  emailLogged: '', // Под каким email залогинился пользователь
  id: null,
  isLoading: false,
  password: '',
  schoolPlatform: null,
  schoolData: null,
  showInfoComponent: false,
  userRole: '',
})
