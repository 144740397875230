import authHelper from '@frontend/helpers/auth'
import NotFound from '@frontend/pages/NotFound.vue'
import auth from '@frontend/routes/auth'
import client from '@frontend/routes/client'
import guest from '@frontend/routes/guest'
import preview from '@frontend/routes/preview'
import student from '@frontend/routes/student'
import UserStore from '@frontend/UserStore'
import { ApiIsAuthorized } from 'ApiRest/Api/Auth/IsAuthorized'
import { ApiUserAction } from 'ApiRest/Api/UserAction'
import { ApiUserRedirect } from 'ApiRest/Api/UserRedirect'
import { trackActionTypes } from 'Helpers/const/trackActionTypes'
import Vue from 'vue'
import Router from 'vue-router'

export default new (class UserRouter {
  constructor() {
    this.router = null
  }

  init() {
    this.router = new Router({
      mode: 'history',

      routes: [
        ...auth,
        ...client,
        ...student,
        ...preview,
        ...guest,

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        }

        if (to.hash) {
          return {
            selector: to.hash,
          }
        }

        return {
          x: 0,
          y: 0,
        }
      },
    })

    /**
     * Разлогинить юзера
     *
     * @param {Function} next
     * @returns {Promise}
     */
    const logout = async (next) => {
      await UserStore.store.dispatch('auth/login/logout')

      return next({
        name: 'login',
      })
    }

    /**
     * Показать форму входа
     *
     * @param {Function} next
     * @returns {boolean}
     */
    const showLoginForm = (next) => {
      next({
        name: 'login',
      })

      Vue.toasted.show('Необходимо авторизоваться', {
        type: 'error',
      })

      return true
    }

    /**
     * Открыть раздел клиента
     *
     * @param {Function} next
     * @returns {Function}
     */
    const showClientSection = (next) =>
      next({
        name: 'client-main',
      })

    /**
     * Открыть раздел ученика
     *
     * @param {Function} next
     * @returns {Function}
     */
    const showStudentSection = (next) =>
      next({
        name: 'student-main',
      })

    /**
     * Открыть админку
     *
     * @returns {boolean}
     */
    const showManagerSection = () => {
      window.location.href = `//${window.domains.admin}`

      return true
    }

    const isClient = () => UserStore.store.getters['common/user/isClient']
    const isStudent = () => UserStore.store.getters['common/user/isStudent']
    const isDemoUser = () => UserStore.store.getters['common/user/isDemoUser']

    /**
     * Отслеживание посещений страниц
     *
     * @param {{name: string, params: Object}} to
     * @param {{params: Object}} from
     * @returns {Promise}
     */
    const trackPage = async (to, from) => {
      // Трекинг всех страниц студента
      await ApiUserAction.post({
        actionTypeId: trackActionTypes.VISIT,
        entityId: null,
        url: window.location.href,
      })

      const lessonRoutes = ['lesson', 'subject-lesson']

      let payload = null

      // Доп. трекинг определенных страниц
      if (
        lessonRoutes.includes(to.name) &&
        from.params.lessonId !== to.params.lessonId
      ) {
        payload = {
          actionTypeId: trackActionTypes.VISIT_LESSON_PAGE,
          entityId: Number(to.params.lessonId),
        }
      }

      if (to.name === 'test-hello-ctp') {
        payload = {
          actionTypeId: trackActionTypes.VISIT_TEST_PAGE,
          entityId: Number(to.params.ctpTestId),
        }
      }

      if (to.name === 'test-hello-fte-ctp') {
        payload = {
          actionTypeId: trackActionTypes.VISIT_TEST_PAGE,
          entityId: Number(to.params.fteCtpTestId),
        }
      }

      if (to.name === 'test-hello-fe-cert') {
        payload = {
          actionTypeId: trackActionTypes.VISIT_TEST_ATTESTATION_PAGE,
          entityId: Number(to.params.feCertTestId),
        }
      }

      if (payload !== null) {
        await ApiUserAction.post(payload)
      }
    }

    /**
     * @param {{query: {try_again: string}, name: string, path: string}} to
     * @param {{name: string}} from
     * @param {Function} next
     * @returns {Promise}
     */
    const processBeforeEach = async (to, from, next) => {
      // TODO: временная фича для IBLS 1
      if (to.query.try_again) {
        Vue.toasted.success('Пожалуйста, авторизуйтесь снова', {
          duration: 5000,
        })
      }

      if (to.name === 'logout') {
        return logout(next)
      }

      if (authHelper.auth.isAuthNotNeeded(to.name)) {
        return next()
      }

      /**
       * @type {{baseRole}} data
       */
      const { data } = await ApiIsAuthorized.post()

      if (data === null) {
        if (authHelper.auth.isAuthPage(to.name)) {
          return next()
        }

        return showLoginForm(next)
      }

      const isManager = await authHelper.role.isManager()

      if (authHelper.auth.isPreview(to.name) && !isManager) {
        return showLoginForm(next)
      }

      if (isManager) {
        if (authHelper.auth.isPreview(to.name)) {
          return next()
        }

        return showManagerSection()
      }

      UserStore.store.commit(
        'common/user/setUserValue',
        {
          key: 'role',
          value: data.baseRole,
        },
        {
          root: true,
        },
      )

      if (
        from === Router.START_LOCATION ||
        authHelper.auth.isAuthPage(to.name)
      ) {
        if (isClient()) {
          await UserStore.store.dispatch('client/profile/fetchProfile', null, {
            root: true,
          })
        }

        if (isStudent()) {
          const { data: urlRedirect } = await ApiUserRedirect.get()

          if (urlRedirect) {
            window.location.href = urlRedirect
          } else {
            await UserStore.store.dispatch(
              'student/profile/fetchProfile',
              null,
              {
                root: true,
              },
            )
          }
        }
      }

      if (to.path !== '/') {
        if (
          isDemoUser() &&
          authHelper.auth.isDemoUserAccessForbidden(to.name)
        ) {
          return next(false)
        }

        return next()
      }

      if (isClient()) {
        return showClientSection(next)
      }

      if (isStudent()) {
        return showStudentSection(next)
      }

      return logout(next)
    }

    const processAfterEach = async (to, from) => {
      if (isStudent()) {
        await trackPage(to, from)
      }
    }

    this.router.beforeEach(processBeforeEach)
    this.router.afterEach(processAfterEach)

    return this.router
  }
})()
