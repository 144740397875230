import module from '@frontend/store/common/module/store'
import supportContract from '@frontend/store/common/support/contact/store'
import supportFAQ from '@frontend/store/common/support/faq/store'
import supportIdea from '@frontend/store/common/support/idea/store'
import supportQuestion from '@frontend/store/common/support/question/store'
import user from '@frontend/store/common/user/store'

export const common = {
  namespaced: true,

  modules: {
    module,
    supportContract,
    supportFAQ,
    supportIdea,
    supportQuestion,
    user,
  },
}
