import {
  studentFillDataTypeInitial,
  studentFillDataTypes,
} from '@frontend/helpers/studentFillDataType'

export const createClient = () => ({
  address: null,
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  phone: '',
  newPhone: '',
  passport: null,
  citizenship: null,
  moscowRegistration: null,
  snils: null,
})

export const createStudent = () => ({
  firstName: '',
  lastName: '',
  middleName: '',

  // id?: Number — если используется существующий ученик
  // (нужно для бэка, чтобы отключить проверку уникальности email)
  sex: null,
  email: '',
  phone: '',
  snils: null,
  certificateFile: null,
  health: {
    hasDisability: false,
    hasRestriction: false,
    hasChronicDisease: false,
  },
  school: null,
  address: null,
  passport: null,
  birthDate: '',
  placeOfBirth: '',
  citizenship: null,
  medicalPolicy: null,
  familyCategory: null,
  birthCertificate: null,
  moscowRegistration: null,
  additionalFiles: [],
})

export default () => ({
  id: null,
  /*
   * Создание заявки.
   * UI. Для отображения данных в селектах, радио, чекбоксах.
   * */
  // Форма обучения
  educationTypes: [],
  // Классы семейного обучения
  familyEducationGrades: [],
  // Тип заполнения анкеты студента
  studentFillDataTypes,
  // Список школ
  schools: [],
  // Список субдоменов
  subDomains: [],
  // Сторонние школы
  isSchools3rdParty: false,
  // Способ подачи заявления
  submissionTypes: [],
  // Степень родства
  kinshipDegrees: [],
  // Гражданство
  citizenships: [],
  // Учебные года
  academicYearPresets: [],
  // Типы регистрации
  moscowRegistrationTypes: [],
  // Список документов, подтверждающих рег-ию
  moscowRegistrationConfirmDocTypes: [],
  // Список всех стран
  countries: [],
  // Пол
  sexes: [],
  // Категория семьи
  familyCategories: [],
  // Наличие шаблона документов
  existingTemplates: [],

  /*
   * Для отправки на сервер.
   * */
  // Статусы валидности данных в компонентах при редактировании Заявки
  validStatus: {
    ContractData: true,
    ClientData: true,
    StudentData: true,
  },
  familyEducationOfferDoc: '',
  // Схема заявки
  contract: {
    status: {
      id: null,
    },
    educationType: null,
    extramuralContractAdditionalInfo: null,
    grade: null,
    submissionType: null,
    studentFillDataType: studentFillDataTypeInitial,
    existingStudent: null,
    school: null,
    academicYearPreset: null,
    kinship: null,
    agreedWithPolitics: false,
    agreedWithPrivacyPolicyAndAgreement: false,
    agreedWithProcessingPDFamily: false,
    agreedWithProcessingPDExtramural: false,
    agreedWithRefundPolicy: false,
    client: createClient(),
    student: createStudent(),
  },
})
