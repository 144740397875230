import ApiFamilyCertifications from 'ApiRest/Api/Account/Student/Test/FamilyCertification/List'
import ApiFeCertComplete from 'ApiRest/Api/Account/Student/Test/FeCertComplete'
import Vue from 'vue'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchCertifications({ commit }) {
    const { data } = await ApiFamilyCertifications.get()

    commit('setCertifications', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async completeCertification({ commit }) {
    try {
      const { data } = await ApiFeCertComplete.put()

      commit('setTheCertification', data)

      // TODO: -> component
      Vue.toasted.show('Аттестация завершена', {
        type: 'success',
        duration: 5000,
      })
    } catch (error) {
      // TODO: -> component
      const message = error?.response?.data?.message || 'Произошла ошибка'

      Vue.toasted.show(message, {
        type: 'error',
        duration: 5000,
      })

      throw error
    }
  },
}
