import { mainTabsStudent } from '@frontend/helpers/mainTabs'

const initialTab = mainTabsStudent[Object.keys(mainTabsStudent)[0]].id

/**
 * @returns {Object}
 */
export default () => {
  const links = [
    {
      link: {
        name: 'subjects',
      },
      label: 'Предметы',
      class: 'm-subjects',
      notifications: 0,
    },
    {
      link: {
        name: 'lessons',
      },
      label: 'Уроки',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'online-lessons',
      },
      label: 'Online-уроки',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-statements',
      },
      label: 'Ведомость',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'student-events',
      },
      label: 'События',
      class: 'm-events',
      notifications: 0,
    },
    {
      link: {
        name: 'certification',
      },
      label: 'Аттестация',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'calendar',
      },
      label: 'Календарь',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'goals',
      },
      label: 'Цели',
      class: 'm-goals',
      notifications: 0,
    },
    // {
    //   link: { name: 'messages' },
    //   label: 'Сообщения',
    //   class: 'm-messages',
    //   notifications: 1
    // },
    {
      link: {
        name: 'notifications',
      },
      label: 'Уведомления',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-news',
      },
      label: 'Новости',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'schedule-tests',
      },
      label: 'Контрольные',
      class: 'm-schedule-tests',
      notifications: 0,
    },
    {
      link: {
        name: 'schedule-lessons',
      },
      label: 'Расписание',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'student-support',
      },
      label: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ]

  return {
    currentTab: initialTab,
    links,
  }
}
