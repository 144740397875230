var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "s-footer" }, [
    _vm.isPreview
      ? _c("ul", { staticClass: "list-unstyled s-footer__nav" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
        ])
      : _c("ul", { staticClass: "list-unstyled s-footer__nav" }, [
          _vm.schoolSite
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-dark",
                    attrs: {
                      href: _vm.schoolSite,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v("\n        Сайт школы\n      ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.schoolPolicyFileUrl
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-dark",
                    attrs: {
                      href: _vm.schoolPolicyFileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v("\n        Политика конфиденциальности\n      ")]
                ),
              ])
            : _vm._e(),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-secondary" }, [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          "\n      © IBLS — Интеллектуальные технологии в образовании\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("id " + _vm._s(_vm.userId))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "link-dark" }, [_vm._v("Сайт школы")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "link-dark" }, [
        _vm._v("Политика конфиденциальности"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }