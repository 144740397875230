import Vue from 'vue'

// Доступные ссылки навигации для клиента, если нет учеников (семейка или заочка)
const familyOrExtramuralEducationAvailableLinks = [
  'client-contracts',
  'client-invoices',
]

// Ссылки навигации для Семейного обучения
const familyEducationLinks = [
  ...familyOrExtramuralEducationAvailableLinks,
  'client-certification',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-events',
]

// Доступные ссылки навигации для клиента, если нет учеников (очка)
const fullTimeEducationAvailableLinks = ['client-invoices']

// Ссылки навигации для Очного обучения
const fullTimeEducationLinks = [
  'client-contracts',
  ...fullTimeEducationAvailableLinks,
  'client-certification',
  'client-statements',
  'client-events',
  'client-schedule-lessons',
  'client-support',
]

// Ссылки навигации для Заочного обучения
const extramuralEducationLinks = [
  'client-contracts',
  'client-invoices',
  'client-certification',
  'client-lessons',
  'client-statements',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-events',
  'client-support',
]

const linksExcludedOnMobile = [
  'client-contracts',
  'client-invoices',
  'client-events',
]

/**
 * ID школ у которых не будут скрываться
 * ссылки указанные в переменной linksAvailableOnlyOnMainSchools
 */
const mainSchoolsIds = [1, 4, 3]

/**
 * наименования ссылок которые будут скрываться
 * если клиент или ученик не относится к школам, ID которых указанны в переменной mainSchoolsIds
 */
const linksAvailableOnlyOnMainSchools = ['client-lessons', 'client-events']

export default {
  // Список ссылок бокового меню
  getLinks(state, getters, rootState, rootGetters) {
    let navLinks = state.links
    let hasCertifications = true
    const currentSchoolId = rootState.client.students.school.id
    const hasModules = rootGetters['common/module/hasModules']
    const isCurrentSchoolIncluded = mainSchoolsIds.includes(currentSchoolId)

    // Если ученик с демо-доступом
    if (rootGetters['client/students/isCurrentStudentDemo']) {
      navLinks = navLinks.filter(
        (link) =>
          ![
            'client-support',
            'client-support-questions',
            'client-support-ideas',
            'client-support-faq',
            'client-support-contacts',
            'client-support-the-question',
            'client-support-the-idea',
          ].includes(link.link.name),
      )
    }

    // Если очный ученик и у клиента нет договоров/черновиков
    if (
      rootGetters['client/students/educationType'].fullTime &&
      !rootGetters['client/contracts/hasContracts']
    ) {
      navLinks = navLinks.filter(
        (link) => link.link.name !== 'client-contracts',
      )
    }

    if (rootGetters['client/students/hasEnrolledStudents']) {
      if (rootGetters['client/students/educationType'].fullTime) {
        navLinks = navLinks.filter((link) =>
          fullTimeEducationLinks.includes(link.link.name),
        )

        hasCertifications =
          rootState.client.fullTimeCertification.certifications.length > 0
      } else {
        if (rootGetters['client/students/educationType'].family) {
          navLinks = navLinks.filter((link) =>
            familyEducationLinks.includes(link.link.name),
          )

          hasCertifications =
            rootState.client.familyCertification.certifications.length > 0
        }

        if (rootGetters['client/students/educationType'].extramural) {
          navLinks = navLinks.filter((link) =>
            extramuralEducationLinks.includes(link.link.name),
          )

          hasCertifications =
            rootGetters[
              'client/extramuralCertification/getAvailableCertifications'
            ].length > 0
        }
      }

      if (!hasCertifications) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-certification',
        )
      }

      const isMobile = Vue.prototype.$isMobile
      const isAvailableOnMobile = (link) =>
        !linksExcludedOnMobile.includes(link.link.name)

      if (isMobile) {
        navLinks = navLinks.filter(isAvailableOnMobile)
      }

      /**
       * Если у клиента школа не Наши пенаты и не Олимп плюс,
       * то мы скрываем в меню кнопки "Уроки" и "События"
       */
      if (!isCurrentSchoolIncluded) {
        navLinks = navLinks.filter(
          (link) => !linksAvailableOnlyOnMainSchools.includes(link.link.name),
        )
      }

      // Если нет доступов к разделу "Поддержка"
      if (!hasModules(['support'])) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-support',
        )
      }

      return navLinks
    }

    // Если выбранный ученик очный и не зачислен
    if (rootGetters['client/students/educationType'].fullTime) {
      navLinks = navLinks.filter((link) =>
        fullTimeEducationAvailableLinks.includes(link.link.name),
      )
      // Если выбранный ученик заочный/семейный и не зачислен
    } else {
      if (rootGetters['client/contracts/hasContracts']) {
        navLinks = navLinks.filter((link) =>
          familyOrExtramuralEducationAvailableLinks.includes(link.link.name),
        )
      } else {
        navLinks = []
      }
    }

    return navLinks
  },
}
