<template>
  <div class="wrapper">
    <slot name="header" />
    <div :class="{ noticed }" class="s-page">
      <slot name="aside" />
      <div v-if="main && !isClient" class="s-content">
        <ContentWithPreloader :is-loading="isLoadingSwitchStudent">
          <main class="h-main">
            <slot name="tabs-nav" />
            <slot name="subjects" />
            <div class="home__col">
              <div class="inner">
                <slot />
              </div>
              <slot name="footer" />
            </div>
          </main>
        </ContentWithPreloader>
      </div>

      <div v-else-if="main && isClient" class="s-content">
        <ContentWithPreloader :is-loading="isLoadingSwitchStudent">
          <main class="s-main">
            <slot name="tabs-nav" />
            <slot />
          </main>
          <slot name="footer" />
        </ContentWithPreloader>
      </div>

      <div v-else class="s-content">
        <main class="s-main">
          <slot />
        </main>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentWithPreloader from '@frontend/ui/ContentWithPreloader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutBase',

  components: {
    ContentWithPreloader,
  },

  props: {
    main: {
      type: Boolean,
      default: false,
    },

    isLoadingSwitchStudent: {
      type: Boolean,
      default: false,
    },

    noticed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('common/user', ['isClient']),
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.h-main {
  @media (min-width: $lg) {
    display: flex;
    align-items: stretch;
    height: 100%;
    border-left: 1px solid rgba(51, 47, 62, 0);
  }
}

.home__col {
  .inner {
    margin-bottom: 24px;
  }

  @media (min-width: $lg) {
    display: flex;
    min-height: calc(100vh - 80px);
    padding: 0 32px;
    flex-direction: column;
    flex-grow: 1;
    justify-content: stretch;

    .inner {
      margin-bottom: 32px;
      flex-grow: 1;
    }

    .s-footer {
      padding-right: 32px;
      padding-left: 32px;
      margin: 0 -16px;
      clear: both;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
</style>
