export const lightColor = 'rgba(51, 47, 62, 0.48)'
export const darkColor = 'rgba(245, 245, 245, 1)'

const tickOptions = {
  fontFamily: '"Rubik", sans-serif',
  fontStyle: '500',
  fontSize: 14,
  fontColor: lightColor,
  lineHeight: 1,
}

export const datasetOptions = {
  // Цвет заливки площади
  backgroundColor: 'transparent',
  // Толщина линии
  borderWidth: 2,
  // Непрерывность графика
  spanGaps: true,
}

export const chartOptions = {
  // Настройки отображения названий списков
  legend: {
    display: false,
  },

  // Настройки тултипа
  tooltips: {
    titleFontFamily: '"Rubik", sans-serif',
    bodyFontFamily: '"Rubik", sans-serif',
    // Отображение данных в тултипе по индексу.
    // Например, если в массивах оценок по русскому и английскому
    // под индексом 1 будут оценки, то они будут в тултипе списком.
    mode: 'index',
  },

  responsive: true,
  // Сохранять пропорции при ресайзе
  maintainAspectRatio: false,

  // Настройка шкал
  scales: {
    yAxes: [
      {
        // Настройки шагов (тиков) по оси
        ticks: {
          // Мин значение по оси Y - минимальная оценка
          min: 1,
          max: 5,
          // Шаг по оси Y
          stepSize: 1,
          ...tickOptions,
        },
      },
    ],

    xAxes: [
      {
        // Настройки шагов (тиков) по оси
        ticks: {
          ...tickOptions,
        },

        // Настройки линий оси
        gridLines: {
          display: false,
        },
      },
    ],
  },
}
