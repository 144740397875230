import authHelper from '@frontend/helpers/auth'
import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiIsAuthorized } from 'ApiRest/Api/Auth/IsAuthorized'
import ApiLogin from 'ApiRest/Api/Auth/Login'
import ApiSchool from 'ApiRest/Api/Auth/Options/School'
import { ApiTwoFactorAuth } from 'ApiRest/Api/Auth/TwoFactorAuth'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import { ApiUserAction } from 'ApiRest/Api/UserAction'
import { ApiUserRedirect } from 'ApiRest/Api/UserRedirect'
import Constants from 'Constants'
import { mainSchool } from 'Helpers/const/AppData'
import { trackActionTypes } from 'Helpers/const/trackActionTypes'
import { isUndefined } from 'lodash'
import Vue from 'vue'

import { eventBus } from '@/pubsub/bus'

const isRoot = {
  root: true,
}

/**
 * @param {Object} args
 * @param {number} args.id
 * @param {string} args.email
 * @param {string} args.role
 */
const globalMessageOnLogin = function ({ id, email, role }) {
  eventBus.$emit('frontend.auth.userLoggedIn', {
    id,
    email,
    role,
  })
}

const globalMessageOnLogoutSilent = function () {
  eventBus.$emit('frontend.auth.userLoggedOutAfterPasswordChange')
}

const globalMessageOnLogout = function () {
  eventBus.$emit('frontend.auth.userLoggedOut')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchSchoolPlatform({ state, commit }) {
    if (!state.schoolPlatform) {
      const domain = window.domains.main
      let { id } = mainSchool

      const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

      commit('setInputValue', {
        name: 'schoolData',
        value: subdomainsList[0],
      })

      if (subdomainsList.length) {
        const school = subdomainsList.find((subdomain) => subdomain.self)

        if (school) {
          id = school.schoolId
        }
      }

      try {
        const { data } = await ApiSchool.get(id)

        commit('setInputValue', {
          name: 'schoolPlatform',
          value: data,
        })
      } catch {
        commit('setInputValue', {
          name: 'schoolPlatform',
          value: {},
        })
      }
    }
  },

  async login({ state, commit, dispatch }) {
    commit('setIsLoading', true)

    const payload = {
      email: state.email,
      password: state.password,
    }

    try {
      const { data } = await ApiLogin.post(payload)
      const { baseRole, id, authCode } = data

      commit('setInputValue', {
        value: id,
        name: 'id',
      })

      commit('setInputValue', {
        value: state.email,
        name: 'emailLogged',
      })

      commit('setInputValue', {
        value: baseRole,
        name: 'userRole',
      })

      commit('setInputValue', {
        value: authCode,
        name: 'authCode',
      })

      if (isUndefined(authCode)) {
        await dispatch('processAfterLogin')
      }
    } catch (error) {
      const status = error?.response?.status
      const code = error?.response?.data?.code
      const message = error?.response?.data?.message

      if (
        status === HttpStatusCodes.Conflict &&
        code === Constants.authorizationResponse.CODE_ERR_USER_EMPTY_PASSWORD
      ) {
        Vue.toasted.show('В целях безопасности просьба задать пароль', {
          type: 'error',
        })

        commit('setInputValue', {
          name: 'showInfoComponent',
          value: true,
        })
      } else if (message) {
        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    } finally {
      commit('setIsLoading', false)
    }
  },

  async processAfterLogin({ state, commit, dispatch, rootGetters }) {
    const { data } = await ApiIsAuthorized.post()

    if (data !== null) {
      commit(
        'common/user/setUserValue',
        {
          key: 'role',
          value: data.baseRole,
        },
        {
          root: true,
        },
      )
    }

    const isClient = rootGetters['common/user/isClient']
    const isStudent = rootGetters['common/user/isStudent']

    if (isClient) {
      await dispatch('client/profile/fetchProfile', null, isRoot)
    }

    if (isStudent) {
      await dispatch('student/profile/fetchProfile', null, isRoot)
    }

    if (isClient || isStudent) {
      globalMessageOnLogin({
        id: state.id,
        email: state.emailLogged,
        role: state.baseRole,
      })
    }

    if (isStudent) {
      const { data: urlRedirect } = await ApiUserRedirect.get()

      if (urlRedirect) {
        window.location.href = urlRedirect
      } else {
        await ApiUserAction.post({
          actionTypeId: trackActionTypes.LOGIN,
          entityId: null,
        })

        await UserRouter.router.push({
          name: 'student-main',
        })
      }
    } else if (isClient) {
      await UserRouter.router.push({
        name: 'client-main',
      })
    } else {
      window.location.href = `//${window.domains.admin}`
    }
  },

  async validateSmsCode(context, payload) {
    await ApiTwoFactorAuth.post(payload)
  },

  async resendSmsCode(context, payload) {
    await ApiTwoFactorAuth.put(payload)
  },

  async logoutSilent() {
    globalMessageOnLogoutSilent()

    await ApiLogin.delete()

    authHelper.actions.clearLocalStorage()
  },

  async logout({ dispatch, commit, rootGetters }) {
    commit('setIsGlobalLoading', true, isRoot)

    const goToLogin = async () => {
      if (UserRouter.router.currentRoute.name !== 'login') {
        await UserRouter.router.push({
          name: 'login',
        })
      }
    }

    const isStudent = rootGetters['common/user/isStudent']

    try {
      if (isStudent) {
        await ApiUserAction.post({
          actionTypeId: trackActionTypes.LOGOUT,
          entityId: null,
        })
      }

      await ApiLogin.delete()

      authHelper.actions.clearLocalStorage()
      globalMessageOnLogout()

      dispatch('resetGlobalState', null, isRoot)

      await goToLogin()
    } catch (error) {
      const status = error?.response?.status
      const message = error?.response?.data?.message

      if (status === HttpStatusCodes.Forbidden) {
        await goToLogin()
      } else if (message) {
        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    } finally {
      commit('setIsGlobalLoading', false, isRoot)
    }
  },
}
