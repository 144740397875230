import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSubjects(state, subjects) {
    state.subjects = subjects
  },

  setSubjectDetail(state, ctp) {
    state.subjectDetailCurrent = ctp
  },
}
