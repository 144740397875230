<template>
  <aside class="s-aside" :class="{ narrow: isHidden }">
    <ElButton v-if="hasLinks" class="s-aside__toggle" @click="toggle">
      <img :src="icons.arrowLeft" alt="arrow-left" />
    </ElButton>
    <nav class="s-aside__menu">
      <!-- TODO: Почему не router-link ? -->
      <div
        v-for="link in links"
        :key="link.link.name"
        class="s-aside__link"
        :class="linkCssClasses(link)"
        @click="goTo(link)"
      >
        <span>{{ link.label }}</span>
        <i v-if="hasNotifications(link)" class="notif">{{
          link.notifications
        }}</i>
      </div>
    </nav>
  </aside>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import iconArrowLeft from 'Static/images/@frontend/common-lk/icons/arrow-left.svg'

export default {
  name: 'TheAside',
  components: {
    ElButton,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },

    userSettings: {
      type: Object,
      default: null,
    },

    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isHidden: false,
    icons: {
      arrowLeft: iconArrowLeft,
    },
  }),

  computed: {
    hasLinks() {
      return this.links.length > 0
    },
  },

  created() {
    this.setInitialData()
  },

  methods: {
    setInitialData() {
      this.isHidden = this.userSettings?.data?.isAsideOpen ?? false
    },

    toggle() {
      this.isHidden = !this.isHidden
      this.$emit('toggle-aside', this.isHidden)
    },

    hasNotifications(link) {
      return link.notifications > 0
    },

    isCurrentRoute(link) {
      return this.$route.name === link.link.name
    },

    linkCssClasses(link) {
      const classes = [link.class]

      if (this.isCurrentRoute(link)) {
        classes.push('router-link-active')
      }

      return classes
    },

    goTo(link) {
      if (this.isPreview || this.isCurrentRoute(link)) {
        return false
      }

      return this.$router.push(link.link)
    },
  },
}
</script>
