import Constants from 'Constants'
import { cloneDeep } from 'lodash'

export default {
  getKinshipDegrees(state) {
    const kinshipDegreesAnotherId = Constants.kinshipDegree.LEGAL_REPRESENTATIVE
    const availablekinshipDegreesIds = [
      Constants.kinshipDegree.MOTHER,
      Constants.kinshipDegree.FATHER,
      Constants.kinshipDegree.LEGAL_REPRESENTATIVE,
    ]

    const tempArr = cloneDeep(state.kinshipDegrees).filter((item) =>
      availablekinshipDegreesIds.includes(item.id),
    )
    const kinshipDegreesAnother = tempArr.find(
      (item) => item.id === kinshipDegreesAnotherId,
    )

    if (kinshipDegreesAnother) {
      kinshipDegreesAnother.name = 'Другое'
    }

    return tempArr
  },

  getStudentMoscowRegistrationTypes(state) {
    const availableTypeIds = [
      Constants.moscowRegistrationType.NO_REGISTRATION,
      Constants.moscowRegistrationType.PERMANENT,
    ]

    let tempArr = cloneDeep(state.childMoscowRegistrationTypes)

    tempArr = tempArr.filter((item) => availableTypeIds.includes(item.id))
    tempArr.forEach((item) => {
      if (item.id === Constants.moscowRegistrationType.NO_REGISTRATION) {
        item.name = 'Нет'
      }

      if (item.id === Constants.moscowRegistrationType.PERMANENT) {
        item.name = 'Да'
      }
    })

    return tempArr
  },
}
