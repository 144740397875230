import ApiPasswordChange from 'ApiRest/Api/Account/Client/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Client/Profile'
import ApiSchool from 'ApiRest/Api/Account/Client/School'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import UserSettings from 'ApiRest/Api/UserSettings'
import { mainSchool } from 'Helpers/const/AppData'

export default {
  fetchProfile({ commit }) {
    return ApiProfile.get().then(({ data }) => {
      commit('setProfile', data)

      commit(
        'common/user/setUserValue',
        {
          key: 'email',
          value: data.email,
        },
        {
          root: true,
        },
      )
    })
  },

  updateProfile({ state }) {
    return ApiProfile.patch(state.profile)
  },

  updateProfilePassword({ state }) {
    return ApiPasswordChange.patch(state.passwords)
  },

  async fetchUserSettings({ commit }) {
    await UserSettings.get({
      name: 'commonSettings',
    }).then(({ data }) => {
      if (data !== null) {
        commit('setUserSettings', data)
      }
    })
  },

  async updateUserSettings({ state, commit }, payload) {
    const params = {
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    }

    await UserSettings.put(params).then(({ data }) => {
      commit('setUserSettings', data)
    })
  },

  async fetchSchoolPlatform({ commit }) {
    const domain = window.domains.main

    const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

    commit('client/theContract/setSubdomains', subdomainsList, {
      root: true,
    })

    let { id } = mainSchool

    if (subdomainsList.length) {
      const school = subdomainsList.find((subdomain) => subdomain.self)

      if (school) {
        id = school.schoolId
      }
    }

    return ApiSchool.get(id).then(({ data }) => {
      commit('setSchoolPlatform', data)
    })
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },
}
