<template>
  <footer class="s-footer">
    <div class="text-secondary">
      © IBLS — Интеллектуальные технологии в&nbsp;образовании
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooterGuest',
}
</script>
