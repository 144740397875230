<template>
  <div class="s-header__rating">
    <div class="rating__row">
      <span class="rating__key">Рейтинг: </span
      ><span class="rating__value">{{ rating.rating }}</span>
    </div>
    <div class="rating__row">
      <span class="rating__key">Обновлен: </span
      ><span class="rating__value">{{ getDateFormatted(rating.date) }}</span>
    </div>
    <div class="rating__row rating__row--list">
      <div class="rating__item">
        <span class="rating__key">Мин.: </span
        ><span class="rating__value">{{ rating.minRating }}</span>
      </div>
      <div class="rating__item">
        <span class="rating__key">Ср.: </span
        ><span class="rating__value">{{ rating.avgRating }}</span>
      </div>
      <div class="rating__item">
        <span class="rating__key">Макс.: </span
        ><span class="rating__value">{{ rating.maxRating }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateTime } from 'Helpers/dateFormatHelper'

export default {
  name: 'TheHeaderRating',

  props: {
    rating: {
      type: Object,
      default: null,
    },
  },

  methods: {
    getDateFormatted(date) {
      return getDateTime(date)
    },
  },
}
</script>
