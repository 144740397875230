import ApiBase from 'ApiRest/ApiBase'

export default new (class ClientTheInvoice extends ApiBase {
  /**
   * @param invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    return this._GET(`/account/client/invoices/${invoiceId}`)
  }

  /**
   * @param invoiceId
   * @param data
   * @returns {Promise}
   */
  put(invoiceId, data) {
    return this._PUT(`/account/client/invoices/${invoiceId}`, data)
  }
})()
