import UserStore from '@frontend/UserStore'

/**
 * @returns {{studentId: number}|{}}
 */
export const getDefaultParamForClient = () => {
  const isClient = UserStore.store.getters['common/user/isClient']
  const currentStudentId =
    UserStore.store.getters['client/students/currentStudentId']

  if (isClient && currentStudentId) {
    return {
      student: {
        id: currentStudentId,
      },
    }
  }

  return {}
}
