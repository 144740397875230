import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiPasswordCreate from 'ApiRest/Api/User/PasswordCreate'
import ApiPasswordCreateTokenCheck from 'ApiRest/Api/User/PasswordCreateTokenCheck'
import Vue from 'vue'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async createPassword({ state }) {
    const data = {
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
      code: state.code,
    }

    // TODO: -> component
    try {
      await ApiPasswordCreate.patch(data)

      Vue.toasted.show('Пароль успешно создан', {
        type: 'success',
      })

      UserRouter.router.push({
        name: 'login',
      })
    } catch (error) {
      const message = error?.response?.data?.message

      if (message) {
        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    }
  },

  async checkToken({ state, commit }) {
    const payload = {
      code: state.code,
    }

    // TODO: -> component
    try {
      const {
        data: { result },
      } = await ApiPasswordCreateTokenCheck.patch(payload)

      commit('setInputValue', {
        name: 'isCodeValid',
        value: result,
      })
    } catch (error) {
      const status = error?.response?.status

      if (status === HttpStatusCodes.NotFound) {
        const message = error?.response?.data?.message ?? 'Произошла ошибка'

        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    }
  },
}
