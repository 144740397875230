import ApiBase from 'ApiRest/ApiBase'

export default new (class CancelDiscount extends ApiBase {
  /**
   * @param invoiceId
   * @returns {Promise}
   */
  put(invoiceId) {
    return this._PUT(`/account/client/invoices/${invoiceId}/cancel-discount`)
  }
})()
