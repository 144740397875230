import UserStore from '@frontend/UserStore'

const checkAuthenticated = (to, from, next) => {
  if (UserStore.store.getters['common/user/isAuthorized']) {
    next('/')
  } else {
    next()
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@frontend/pages/auth/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/create-password', // в емейле указывается ссылка с этим путем. При изменении,
    // согласовать с бэком
    name: 'password-create',
    component: () =>
      import(
        /* webpackChunkName: "password-create" */ '@frontend/pages/auth/PasswordCreate.vue'
      ),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '@frontend/pages/auth/PasswordReset.vue'
      ),
  },
  {
    path: '/change-password', // в емейле указывается ссылка с этим путем. При изменении,
    // согласовать с бэком
    name: 'password-reset-confirm',
    component: () =>
      import(
        /* webpackChunkName: "password-reset-confirm" */ '@frontend/pages/auth/PasswordResetConfirm.vue'
      ),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '@frontend/pages/auth/Registration.vue'
      ),
  },
  {
    path: '/demo-registration',
    name: 'demo-registration',
    component: () =>
      import(
        /* webpackChunkName: "demo-registration" */ '@frontend/pages/auth/DemoRegistration.vue'
      ),
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ '@frontend/pages/auth/ConfirmEmail.vue'
      ),
  },
]

routes.forEach((route) => {
  if (route.name !== 'password-create') {
    route.beforeEnter = checkAuthenticated
  }
})

export default routes
