import { getDefaultParamForClient } from '@frontend/getDefaultParam'
import { ApiSupportFAQList } from 'ApiRest/Api/Support/FAQ/List'

export default {
  async fetchFAQItems({ commit }) {
    const parameters = {
      expand: 'items',
      ...getDefaultParamForClient(),
    }

    const { data } = await ApiSupportFAQList.get(parameters)

    commit('setFAQItems', data)
  },
}
