import { getDefaultParamForClient } from '@frontend/getDefaultParam'
import { ApiModulatorActiveModules } from 'ApiRest/Api/Modulator/ActiveModules/List'

export default {
  async fetchActiveModules({ commit }) {
    const { data } = await ApiModulatorActiveModules.get(
      getDefaultParamForClient(),
    )

    commit('setActiveModules', data)
  },
}
