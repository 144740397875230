import createState from '@frontend/store/client/main/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCurrentTab(state, tab = '') {
    state.currentTab = tab
  },
}
