import { chartPeriodConstants } from 'Helpers/const/chartPeriod'
import { getDate } from 'Helpers/dateFormatHelper'
import { datasetOptions } from 'Helpers/lineChartDefaultOptions'
import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

const allSubject = {
  id: 0,
  name: 'Все предметы',
  color: 'ff0000',
}

const getAllSubjectsMarks = (marksTotal) => ({
  subject: allSubject,
  periods: marksTotal.periods || [],
})

const generateDataset = (averageMark) => ({
  label: averageMark.subject.name,
  borderColor: `#${averageMark.subject.color}`,
  hidden: true,
  data: averageMark.periods.map((period) => period.value),
  ...datasetOptions,
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setAverageMarksMonthly(state, { marks, marksTotal }) {
    state.averageMarksMonthly = [getAllSubjectsMarks(marksTotal), ...marks]
  },

  setAverageMarksWeekly(state, { marks, marksTotal }) {
    state.averageMarksWeekly = [getAllSubjectsMarks(marksTotal), ...marks]
  },

  setSubjects(state) {
    state.subjects = state.averageMarksMonthly.map(
      (averageMark) => averageMark.subject,
    )
  },

  composeChartData(state) {
    let labels = []
    const datasets = []

    if (state.chartPeriod.id === chartPeriodConstants.MONTH) {
      labels = state.averageMarksMonthly[0]?.periods.map((period) =>
        getDate(period.period, 'LLL'),
      )

      state.averageMarksMonthly.forEach((averageMark) => {
        const dataset = generateDataset(averageMark)

        datasets.push(dataset)
      })
    }

    if (state.chartPeriod.id === chartPeriodConstants.WEEK) {
      const periodsLength = state.averageMarksWeekly[0]?.periods?.length
      const weekNumbers = []

      for (let i = 1; i < periodsLength; i++) {
        weekNumbers.push(i)
      }

      labels = weekNumbers

      state.averageMarksWeekly.forEach((averageMark) => {
        const dataset = generateDataset(averageMark)

        datasets.push(dataset)
      })
    }

    state.chartData = {
      labels,
      datasets,
    }
  },

  setChartPeriod(state, payload) {
    if (payload) {
      state.chartPeriod = payload
    } else {
      state.chartPeriod = state.chartPeriods[0]
    }
  },

  setAcademicYear(state, payload) {
    state.academicYear = payload
  },

  setSubjectsSelected(state, payload) {
    state.subjectsSelected = payload
  },

  toggleChartsVisibility(state) {
    const subjectsSelectedIndexes = []

    // Индексы активных предметов
    state.subjects.forEach((subject, subjectIndex) => {
      state.subjectsSelected.forEach((subjectSelected) => {
        if (subject.id === subjectSelected.id) {
          subjectsSelectedIndexes.push(subjectIndex)
        }
      })
    })

    // Сброс видимости линий графика
    state.subjects.forEach((subject, subjectIndex) => {
      state.chartData.datasets[subjectIndex].hidden = true
    })

    // Отображение линий графика в соответствии с индексом активных предметов
    subjectsSelectedIndexes.forEach((index) => {
      state.chartData.datasets[index].hidden = !state.chartData.datasets[index]
        .hidden
    })
  },

  increaseChartKey(state) {
    state.chartKey += 1
  },
}
