import { mainTabsClient } from '@frontend/helpers/mainTabs'

const initialTab = mainTabsClient[Object.keys(mainTabsClient)[0]].id

/**
 * @returns {Object}
 */
export default () => {
  const links = [
    {
      link: {
        name: 'client-contracts',
      },
      label: 'Договоры',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-invoices',
      },
      label: 'Счета',
      class: 'm-bills',
      notifications: 0,
    },
    {
      link: {
        name: 'client-certification',
      },
      label: 'Аттестация',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-lessons',
      },
      label: 'Уроки',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-statements',
      },
      label: 'Ведомость',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'client-schedule-lessons',
      },
      label: 'Расписание',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-calendar',
      },
      label: 'Календарь',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'client-goals',
      },
      label: 'Цели',
      class: 'm-goals',
      notifications: 0,
    },
    // {
    //   link: { name: 'client-messages' },
    //   label: 'Сообщения',
    //   class: 'm-messages',
    //   notifications: 1
    // },
    // {
    //   link: { name: 'client-notifications' },
    //   label: 'Уведомления',
    //   class: 'm-notifications',
    //   notifications: 5
    // },
    {
      link: {
        name: 'client-news',
      },
      label: 'Новости',
      class: 'm-news',
      notifications: 0,
    },

    {
      link: {
        name: 'client-events',
      },
      label: 'События',
      class: 'm-events',
      notifications: 0,
    },
    {
      link: {
        name: 'client-support',
      },
      label: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ]

  return {
    currentTab: initialTab,
    links,
  }
}
