import Constants from 'Constants'

export default {
  isPeriodEqualsStreamAcademicPreset(state, getters, rootState) {
    return (
      rootState.client.students.currentStudent.streamAcademicYearPreset?.id ===
      state.theAcademicYear?.id
    )
  },

  isItemsFilterVisible(state, getters, rootState) {
    return (
      rootState.client.students.currentStudent.hasUnactualGroups &&
      getters.isPeriodEqualsStreamAcademicPreset
    )
  },

  statementsGroupedByCtpType(state) {
    const groupedItems = []

    state.statements.forEach((statement) => {
      const groupIndex = groupedItems.findIndex(
        (item) =>
          item.type.id === statement.type.id &&
          item.academicYearType.id === statement.academicYearType.id,
      )

      if (groupIndex >= 0) {
        groupedItems[groupIndex].items.push(statement)
      } else {
        const item = {
          group: statement.group,
          type: statement.type,
          academicYearType: statement.academicYearType,
          items: [statement],
        }

        groupedItems.push(item)
      }
    })

    return {
      main: groupedItems.filter(
        (statement) => statement.type.id === Constants.ctpType.MAIN,
      ),

      additional: groupedItems.filter(
        (statement) => statement.type.id === Constants.ctpType.ADDITIONAL,
      ),
    }
  },
}
