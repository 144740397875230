import { getModuleSettingValue, hasModules } from 'Helpers/accessControl/module'

export default {
  hasModules: (state) => (moduleIdsSearched) =>
    hasModules(state.activeModules, moduleIdsSearched),

  moduleSettingValue: (state) => (moduleId, settingId) =>
    getModuleSettingValue({
      activeModules: state.activeModules,
      moduleId,
      settingId,
    }),
}
