var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "s-aside", class: { narrow: _vm.isHidden } },
    [
      _vm.hasLinks
        ? _c(
            "ElButton",
            { staticClass: "s-aside__toggle", on: { click: _vm.toggle } },
            [
              _c("img", {
                attrs: { src: _vm.icons.arrowLeft, alt: "arrow-left" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "s-aside__menu" },
        _vm._l(_vm.links, function (link) {
          return _c(
            "div",
            {
              key: link.link.name,
              staticClass: "s-aside__link",
              class: _vm.linkCssClasses(link),
              on: {
                click: function ($event) {
                  return _vm.goTo(link)
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(link.label))]),
              _vm._v(" "),
              _vm.hasNotifications(link)
                ? _c("i", { staticClass: "notif" }, [
                    _vm._v(_vm._s(link.notifications)),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }