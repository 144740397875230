import authHelper from '@frontend/helpers/auth'
import Vue from 'vue'

/** Ссылки навигации, доступные для ученика, если есть блокирующие аттестации */
const linksAvailableWhenHasBlockingCertifications = ['certification']

/** Ссылки навигации для Заочного обучения */
const linksForExtramuralEducation = [
  'subjects',
  'lessons',
  'online-lessons',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-events',
  'student-support',
]

/** Ссылки навигации для Семейного обучения */
const linksForFamilyEducation = [
  'subjects',
  'lessons',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-events',
]

/** Ссылки навигации для Очного обучения */
const linksForFullTimeEducation = [
  'subjects',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-events',
  'schedule-tests',
  'schedule-lessons',
  'student-support',
]

/** Ссылки навигации, доступные для ЦОК */
const linksAvailableWhenSchoolIsEducont = [
  'subjects',
  'lessons',
  'calendar',
  'goals',
  'notifications',
]

const linksExcludedOnMobile = ['student-events']

// TODO: убрать хардкодные id

/**
 * Список ID основных школ
 */
const mainSchoolIds = [1, 4, 3]

/**
 * ID школы "Обдорская гимназия"
 */
const obdorskayaSchoolId = 8

/**
 * Список ссылок навигации, доступных для основных школ
 */
const linksAvailableWhenSchoolIsMain = ['student-events']

/**
 * Список ID школ, для которых доступен раздел "Онлайн-уроки"
 */
const linkOnlineLessonsAvailableSchoolIds = [
  ...mainSchoolIds,
  obdorskayaSchoolId,
]

export default {
  getLinks(state, getters, rootState, rootGetters) {
    let navLinks = [...state.links]

    const isMobile = Vue.prototype.$isMobile

    const currentSchoolId = rootState.student.profile.school.id
    const isEducontCurrentSchool =
      rootState.student.profile.profile?.school?.isEducont ?? false
    const isMainCurrentSchool = mainSchoolIds.includes(currentSchoolId)
    const hasOnlineLessonsCurrentSchool = linkOnlineLessonsAvailableSchoolIds.includes(
      currentSchoolId,
    )
    const hasBlockingCertifications =
      rootGetters['student/extramuralCertification/hasBlockingCertifications']
    const educationType = rootGetters['student/profile/educationType']
    const familyCertifications =
      rootState.student.familyCertification.certifications
    const extramuralCertifications =
      rootGetters['student/extramuralCertification/getAvailableCertifications']
    const fullTimeCertifications =
      rootGetters['student/fullTimeCertification/getAvailableCertifications']
    const isDemoUser = rootGetters['common/user/isDemoUser']
    const hasCertifications = [
      extramuralCertifications,
      familyCertifications,
      fullTimeCertifications,
    ].some((certifications) => certifications.length > 0)
    const hasModules = rootGetters['common/module/hasModules']

    // Если используется мобильная версия
    if (isMobile) {
      navLinks = navLinks.filter(
        (link) => !linksExcludedOnMobile.includes(link.link.name),
      )
    }

    // Если ученик с демо-доступом
    if (isDemoUser) {
      navLinks = navLinks.filter(
        (link) => !authHelper.auth.isDemoUserAccessForbidden(link.link.name),
      )
    }

    if (educationType.extramural) {
      navLinks = navLinks.filter((link) =>
        linksForExtramuralEducation.includes(link.link.name),
      )
    } else if (educationType.family) {
      navLinks = navLinks.filter((link) =>
        linksForFamilyEducation.includes(link.link.name),
      )
    } else if (educationType.fullTime) {
      navLinks = navLinks.filter((link) =>
        linksForFullTimeEducation.includes(link.link.name),
      )
    }

    // Если школа Educont
    if (isEducontCurrentSchool) {
      navLinks = navLinks.filter((link) =>
        linksAvailableWhenSchoolIsEducont.includes(link.link.name),
      )
    }

    // Если есть блокирующие аттестации
    if (hasBlockingCertifications) {
      navLinks = navLinks.filter((link) =>
        linksAvailableWhenHasBlockingCertifications.includes(link.link.name),
      )
    }

    // Если школы нет в списке mainSchoolIds
    if (!isMainCurrentSchool) {
      navLinks = navLinks.filter(
        (link) => !linksAvailableWhenSchoolIsMain.includes(link.link.name),
      )
    }

    // Если у школы нет онлайн-уроков или нет доступов к разделу "Онлайн-уроки"
    if (!hasOnlineLessonsCurrentSchool || !hasModules(['online-lesson'])) {
      navLinks = navLinks.filter((link) => link.link.name !== 'online-lessons')
    }

    // Скрыть запрещенные разделы для ученика с демо-доступом
    if (isDemoUser) {
      navLinks = navLinks.filter(
        (link) => !authHelper.auth.isDemoUserAccessForbidden(link.link.name),
      )
    }

    // Если нет аттестаций
    if (!hasCertifications) {
      navLinks = navLinks.filter((link) => link.link.name !== 'certification')
    }

    // Если нет доступов к разделу "Поддержка"
    if (!hasModules(['support'])) {
      navLinks = navLinks.filter((link) => link.link.name !== 'student-support')
    }

    return navLinks
  },
}
