import { getDefaultParamForClient } from '@frontend/getDefaultParam'
import { ApiSupportContactList } from 'ApiRest/Api/Support/Contact/List'

export default {
  async fetchContacts({ commit }) {
    const { data } = await ApiSupportContactList.get(getDefaultParamForClient())

    commit('setContact', data)
  },
}
