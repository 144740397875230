<template>
  <router-link class="home__subject" :to="subjectDetailUrl">
    <div v-if="educationType.fullTime" class="form-row">
      <div class="subject__name col-8">
        {{ name }}
        <span v-if="subtitle" class="t-tag">{{ subtitle }}</span>
        <span v-if="hasTrainingPrograms" class="t-tag">{{
          trainingPrograms
        }}</span>
      </div>

      <div class="subject__name col-4 text-right">{{ mark }}</div>
    </div>

    <div v-else class="subject__name">
      {{ name }}
      <span v-if="subtitle" class="t-tag">{{ subtitle }}</span>
      <span v-if="hasTrainingPrograms" class="t-tag">{{
        trainingPrograms
      }}</span>
      <div v-if="isAdditional" class="t-tag">{{ grade }}</div>
    </div>

    <ProgressBar
      v-if="!educationType.fullTime"
      :color="color"
      :progress="progress"
      :checkpoint="checkpoint"
    />

    <div v-if="educationType.family" class="home__subject-stat row">
      <div class="col-3">
        <span class="t-tag">Тема</span>{{ topicsProgress }}
      </div>

      <div class="col-4">
        <span class="t-tag">Освоение</span>{{ miniTestsProgress }}
      </div>
    </div>
  </router-link>
</template>

<script>
import ProgressBar from '@frontend/ui/ProgressBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectItemPreview',

  components: {
    ProgressBar,
  },

  props: {
    subject: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('student/profile', ['educationType']),

    subjectDetailUrl() {
      return {
        name: 'subject',
        params: {
          subjectId: this.subject.id,
          groupId: this.subject.group.id,
        },
      }
    },

    /**
     * @returns {number}
     */
    progress() {
      return parseInt((this.ctpLessonsPassed / this.ctpLessonsTotal) * 100, 10)
    },

    /**
     * @returns {number}
     */
    checkpoint() {
      return parseInt((this.ctpLessonsOver / this.ctpLessonsTotal) * 100, 10)
    },

    /**
     * @returns {string}
     */
    color() {
      return this.subject.color
    },

    /**
     * @returns {boolean}
     */
    isAdditional() {
      return Boolean(this.subject.isAdditional)
    },

    /**
     * @returns {boolean}
     */
    hasTrainingPrograms() {
      return this.subject.trainingPrograms?.length > 0
    },

    /**
     * @returns {boolean}
     */
    trainingPrograms() {
      return this.subject.trainingPrograms.map((item) => item.name).join(', ')
    },

    /**
     * @returns {string}
     */
    name() {
      return this.subject.name
    },

    /**
     * @returns {string}
     */
    grade() {
      return `(${this.subject.group.gradeId} класс)`
    },

    /**
     * @returns {string}
     */
    subtitle() {
      return this.subject.subtitle
    },

    /**
     * @returns {number}
     */
    ctpLessonsPassed() {
      return Number(this.subject.ctpLessonsPassed)
    },

    /**
     * @returns {number}
     */
    ctpLessonsOver() {
      return Number(this.subject.ctpLessonsOver)
    },

    /**
     * @returns {number}
     */
    ctpLessonsTotal() {
      return Number(this.subject.ctpLessonsTotal)
    },

    mark() {
      const hasTilda = this.subject.showTilda
      let result = ''

      if (this.subject.mark) {
        if (hasTilda) {
          result = '~'
        }

        result += this.subject.mark
      } else {
        result = '—'
      }

      return result
    },

    /**
     * @returns {string}
     */
    miniTestsProgress() {
      const progress = parseInt(
        (this.subject.ctpMiniTestsCompleted / this.subject.ctpMiniTestsTotal) *
          100,
        10,
      )

      return `${progress || 0}%`
    },

    /**
     * @returns {string}
     */
    topicsProgress() {
      const progress = parseInt(
        (this.subject.ctpTopicsCompleted / this.subject.ctpTopicsTotal) * 100,
        10,
      )

      return `${progress || 0}%`
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.home {
  &__subject {
    display: block;
    margin-bottom: 16px;
    color: $black;

    &:hover {
      color: inherit;
      opacity: 0.48;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-stat {
      position: relative;
      padding-bottom: 16px;
      margin-top: 8px;
      font-weight: 500;
    }

    .subject__name {
      margin-bottom: 8px;
    }

    .note {
      display: inline;
      font-size: 26px;
      line-height: 18px;
      color: $secondary;
      vertical-align: middle;
    }
  }
}
</style>
