import getErrorsList from '@frontend/helpers/getErrorsList'
import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiCommonContract from 'ApiRest/Api/Account/Client/CommonContract'
import { ApiContractDocument } from 'ApiRest/Api/Account/Client/ContractDocument'
import ApiFamilyEducationOfferForSchool from 'ApiRest/Api/Account/Client/FamilyEducationOfferForSchool'
import ApiRequest from 'ApiRest/Api/Account/Client/Request'
import ApiRequestStudent from 'ApiRest/Api/Account/Client/Request/Student'
import ApiRequestDraft from 'ApiRest/Api/Account/Client/RequestDraft'
import { ApiCheckDocuments } from 'ApiRest/Api/Enrollment/Contract/CheckDocuments'
import ApiOption from 'ApiRest/Api/Option'
import Constants from 'Constants'
import { mainSchool } from 'Helpers/const/AppData'
import DownloadHelper from 'Helpers/DownloadHelper'
import Vue from 'vue'

export default {
  async fetchDraft({ commit }, id) {
    try {
      const { data, status } = await ApiRequestDraft.get(id)

      if (status === HttpStatusCodes.Ok) {
        commit('setDraftData', data)
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show('Заявка не найдена', {
          type: 'error',
        })

        UserRouter.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async createDraft({ state, commit, dispatch }) {
    try {
      const { data, status } = await ApiRequestDraft.post(state.contract)

      if (status === HttpStatusCodes.Created) {
        commit('setDraftData', data)

        // TODO: -> component
        Vue.toasted.show('Данные успешно сохранены', {
          type: 'success',
          duration: 5000,
        })

        await dispatch('client/contracts/fetchDrafts', null, {
          root: true,
        })
      }
    } catch (error) {
      // TODO: -> component
      Vue.toasted.show(
        `Возникла ошибка при сохранении черновика. Попробуйте еще раз`,
        {
          type: 'error',
          duration: 5000,
        },
      )

      throw error
    }
  },

  async updateDraft({ state }) {
    try {
      const { status } = await ApiRequestDraft.patch(
        state.contract.id,
        state.contract,
      )

      // TODO: -> component
      if (status === HttpStatusCodes.Ok) {
        Vue.toasted.show('Данные успешно сохранены', {
          type: 'success',
          duration: 5000,
        })
      }
    } catch (error) {
      // TODO: -> component
      Vue.toasted.show(
        `Возникла ошибка при сохранении черновика. Попробуйте еще раз`,
        {
          type: 'error',
          duration: 5000,
        },
      )

      throw error
    }
  },

  async saveDraft({ state, dispatch }) {
    if (state.contract?.id) {
      await dispatch('updateDraft')
    } else {
      await dispatch('createDraft')
    }
  },

  async fetchRequest({ commit }, id) {
    try {
      const { data, status } = await ApiRequest.get(id)

      if (status === HttpStatusCodes.Ok) {
        commit('setRequestData', data)
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show('Договор не найден', {
          duration: 5000,
          type: 'error',
        })

        UserRouter.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async createRequest({ state, commit }) {
    try {
      // TODO: Заменить на ConvertEmptyDataToDefaultValue
      commit('deleteStudentAdditionalData')
      commit('deleteStudentSnils')
      commit('deleteClientSnils')

      const { data, status } = await ApiRequest.post(state.contract)

      // TODO: -> component
      if (status === HttpStatusCodes.Created) {
        Vue.toasted.show('Заявка успешно отправлена менеджеру.', {
          type: 'success',
          duration: 5000,
        })

        if (state.contract?.id) {
          await ApiRequestDraft.delete(state.contract.id)
        }

        if (
          state.contract.educationType.id === Constants.educationType.FAMILY
        ) {
          UserRouter.router.push({
            name: 'client-the-invoice',
            params: {
              invoiceId: data.invoice.id,
            },
          })
        } else {
          UserRouter.router.push({
            name: 'client-contracts',
          })
        }
      }
    } catch (error) {
      // TODO: -> component
      Vue.toasted.show(getErrorsList(error), {
        type: 'error',
        keepOnHover: true,
        closeOnSwipe: false,
        duration: Infinity,
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })

      throw error
    }
  },

  async updateRequest({ state }) {
    try {
      await ApiRequest.put(state.contract.id, state.contract)

      Vue.toasted.show('Данные успешно обновлены.', {
        type: 'success',
        duration: 5000,
      })

      UserRouter.router.push({
        name: 'client-contracts',
      })
    } catch (error) {
      // TODO: -> component
      Vue.toasted.show(getErrorsList(error), {
        type: 'error',
        keepOnHover: true,
        closeOnSwipe: false,
        duration: Infinity,
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })

      throw error
    }
  },

  async fetchContract({ commit }, contractId) {
    try {
      const { data, status } = await ApiCommonContract.get(contractId)

      if (status === HttpStatusCodes.Ok) {
        commit('setContractData', data)
      }
    } catch (error) {
      if (error.response.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show('Договор не найден', {
          duration: 5000,
          type: 'error',
        })

        UserRouter.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async fetchExistingStudent({ commit }, studentId) {
    const { data } = await ApiRequestStudent.get(studentId)

    commit('setContractStudent', data)
  },

  fetchEducationTypes({ commit }) {
    return ApiOption.getEducationTypes().then(({ data }) => {
      commit('setEducationTypes', data)
    })
  },

  fetchFamilyEducationGrades({ commit }) {
    // Семейное обучение доступно только для школы "Наши пенаты"
    const params = {
      school: {
        id: mainSchool.id,
      },
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    return ApiOption.getGrades(params).then(({ data }) => {
      commit('setFamilyEducationGrades', data)
    })
  },

  fetchEnrollmentContractSubmissionTypes({ state, commit }) {
    return ApiOption.getEnrollmentContractSubmissionTypes().then(({ data }) => {
      commit('setEnrollmentContractSubmissionTypes', data)
      // TODO: когда добавится оферта - убрать
      commit('setSubmissionType', state.submissionTypes[0])
    })
  },

  fetchSchools({ state, commit }) {
    return ApiOption.getSchools({
      expand: 'code,city,educationTypes,grades,currentAcademicYearPreset',
      educationType: {
        id: Constants.educationType.EXTRAMURAL,
      },
    }).then(({ data: schools }) => {
      const hasSubDomains = Boolean(state.subDomains?.length)

      commit('setIsSchools3rdParty', hasSubDomains)

      let result = []

      if (state.isSchools3rdParty) {
        const subDomainsIds = state.subDomains.map(
          (subDomain) => subDomain.schoolId,
        )

        result = schools.filter((school) => subDomainsIds.includes(school.id))
      } else {
        result = schools.filter((school) => !school.is3rdParty)
      }

      commit('setSchools', result)
    })
  },

  fetchKinshipDegrees({ commit }) {
    return ApiOption.getKinshipDegrees().then(({ data }) => {
      commit('setKinshipDegrees', data)
    })
  },

  fetchCitizenships({ commit }) {
    return ApiOption.getCitizenships().then(({ data }) => {
      commit('setCitizenships', data)
    })
  },

  fetchMoscowRegistrationTypes({ commit }) {
    return ApiOption.getMoscowRegistrationTypes().then(({ data }) => {
      commit('setMoscowRegistrationTypes', data)
    })
  },

  fetchMoscowRegistrationConfirmDocTypes({ commit }) {
    return ApiOption.getMoscowRegistrationConfirmDocTypes().then(({ data }) => {
      commit('setMoscowRegistrationConfirmDocTypes', data)
    })
  },

  fetchCountries({ commit }) {
    return ApiOption.getCountries().then(({ data }) => {
      commit('setCountries', data)
    })
  },

  fetchSexes({ commit }) {
    return ApiOption.getSexes().then(({ data }) => {
      commit('setSexes', data)
    })
  },

  fetchFamilyCategories({ commit }) {
    return ApiOption.getFamilyCategories().then(({ data }) => {
      commit('setFamilyCategories', data)
    })
  },

  fetchFamilyEducationOfferForSchool({ commit }, schoolId) {
    return ApiFamilyEducationOfferForSchool.get(schoolId).then(({ data }) => {
      commit('setFamilyEducationOfferForSchool', data)
    })
  },

  async fetchAcademicYearPresetOption({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setAcademicYearPresetOption', data)
  },

  async downloadContractDocument({ state }, documentType) {
    const response = await ApiContractDocument.get(
      state.contract.id,
      documentType,
    )

    DownloadHelper.downloadBlob(response)
  },

  async fetchCheckTemplatesExistence({ state, commit }) {
    const { data } = await ApiCheckDocuments.get(state.contract.id)

    commit('setExistingTemplates', data)
  },
}
