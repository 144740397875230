import Constants from 'Constants'

const statuses = [
  {
    id: Constants.enrollmentContractStatus.REQUEST_PENDING,
    nameForManager: 'Новая заявка',
    nameForClient: 'Заявка на рассмотрении',
  },
  {
    id: Constants.enrollmentContractStatus.REQUEST_FIX_REQUIRED,
    nameForManager: 'Возвращена клиенту',
    nameForClient: 'Заявка требует исправления',
  },
  {
    id: Constants.enrollmentContractStatus.REQUEST_REJECTED,
    nameForManager: 'Заявка в корзине',
    nameForClient: 'Заявка отклонена',
  },
  {
    id: Constants.enrollmentContractStatus.REQUEST_REMOVED_BY_CLIENT,
    nameForManager: 'Заявка удалена клиентом',
    nameForClient: 'Удалена',
  },
  {
    id: Constants.enrollmentContractStatus.REQUEST_WITHDRAWN_BY_CLIENT,
    nameForManager: 'Отозвана клиентом',
    nameForClient: 'Отозвана',
  },
  {
    id: Constants.enrollmentContractStatus.REQUEST_APPROVED,
    nameForManager: 'Договор не сформирован',
    nameForClient: 'Заявка одобрена',
  },
  {
    id: Constants.enrollmentContractStatus.AWAITING_SIGNATURE,
    nameForManager: 'Ожидание документов',
    nameForClient: 'Ожидание документов',
  },
  {
    id: Constants.enrollmentContractStatus.SIGNED,
    nameForManager: 'Договор заключен',
    nameForClient: 'Договор заключен',
  },
  {
    id: Constants.enrollmentContractStatus.COMPLETED,
    nameForManager: 'Договор завершен',
    nameForClient: 'Договор завершен',
  },
  {
    id: Constants.enrollmentContractStatus.TERMINATED,
    nameForManager: 'Договор расторгнут',
    nameForClient: 'Договор расторгнут',
  },
]

export default {
  /**
   * Получает объект статуса, содержащий названия статусов для менеджеров (nameForManager), для клиентов (nameForClient)
   *
   * @param {Object} status - Объект статуса на вход, содержащий ID по которому будет искаться соответствие
   */
  getTransformedStatus(status) {
    if (status?.id) {
      return statuses.find((item) => item.id === status.id)
    }

    return null
  },
  /**
   * Перобразует статусы в нужный вид (для менеджера / для клиента)
   *
   * @param {Array} data - массив заявок/договоров, у которых нужно преобразовать стату
   * @param {boolean} isManager - признак менеджера, если True - преобразовать в статус для менеджера, False - для клиента
   * @param {boolean} isOption - для вывода списка в дропдауне левого фильтра для менеджера или клиента
   */
  transformStatuses(data, isManager = false, isOption = false) {
    if (data) {
      data.forEach((item) => {
        const itemStatus = item.status

        if (itemStatus) {
          const findStatus = statuses.find(
            (status) => status.id === itemStatus.id,
          )

          if (findStatus) {
            if (isManager) {
              itemStatus.name = findStatus.nameForManager
            } else {
              itemStatus.name = findStatus.nameForClient
            }
          }
        }

        if (isOption) {
          const findStatus = statuses.find((status) => status.id === item.id)

          if (findStatus) {
            if (isManager) {
              item.name = findStatus.nameForManager
            } else {
              item.name = findStatus.nameForClient
            }
          }
        }
      })
    }

    return data
  },
}
