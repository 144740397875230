var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoaderFullScreen")
    : _c(
        "LayoutBase",
        {
          attrs: { main: _vm.main, noticed: _vm.isDemoProfile },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("TheHeaderStudent", {
                    attrs: {
                      abbreviation: _vm.nameAbbreviation,
                      avatar: _vm.avatar,
                      "full-name": _vm.fullNameWithShortLastName,
                      grade: Number(_vm.profile.grade),
                      letter: _vm.letter,
                      "has-blocking-certifications":
                        _vm.hasBlockingCertifications,
                      links: _vm.getLinks,
                      "notice-additional-message": _vm.demoMessage,
                      "notice-text": _vm.demoAccessTimeText,
                      rating: _vm.profile.rating,
                      "school-name": _vm.schoolName,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "aside",
              fn: function () {
                return [
                  _c("TheAside", {
                    attrs: {
                      links: _vm.getLinks,
                      "user-settings": _vm.profile.userSettings,
                    },
                    on: { "toggle-aside": _vm.toggleAside },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "tabs-nav",
              fn: function () {
                return [
                  _c("TabsNav", {
                    attrs: {
                      "current-tab": _vm.currentTab,
                      tabs: _vm.mainTabsFiltered,
                    },
                    on: { change: _vm.onChangeTabNav },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "subjects",
              fn: function () {
                return [
                  _c("Subjects", {
                    attrs: {
                      "current-tab": _vm.currentTab,
                      "subjects-additional": _vm.getSubjectsAdditional,
                      "subjects-optional": _vm.getSubjectsOptional,
                      "subjects-required": _vm.getSubjectsRequired,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("TheFooter", {
                    attrs: {
                      school: _vm.profile.school,
                      "user-id": _vm.profile.id,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c("router-view"),
          _vm._v(" "),
          _vm._v(" "),
          _c("BtnUp"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }