<template>
  <header
    ref="header"
    class="s-header-wrapper"
    :class="{ noticed: noticeText }"
  >
    <div v-if="noticeText" class="s-header__notice">
      <span>
        {{ noticeText }}

        <span class="notice-text--thin">{{ noticeAdditionalMessage }}</span>
      </span>
    </div>

    <div class="s-header" :class="classHeaderClient">
      <div class="s-logo s-header__logo">
        <h2 class="s-school-name">{{ schoolName }}</h2>

        <router-link :to="toMain">
          <img :src="logo" alt="IBLS логотип" />
        </router-link>
      </div>

      <div class="s-header__profile d-lg-none d-xl-flex">
        <ElAvatar :abbreviation="abbreviation" :image="avatar" shape="circle" />

        <span class="text">
          <span class="name">{{ fullName }}</span>
        </span>
      </div>

      <div v-if="students.length" class="s-header__student-select">
        <v-select
          :value="currentStudentFormatted"
          class="v-select-student"
          :options="studentsFormatted"
          :clearable="false"
          :searchable="false"
          label="abbr"
          @input="selectStudent"
        >
          <template #selected-option="{ abbr, name, gradeName, photo }">
            <div class="s-header__student">
              <ElAvatar :abbreviation="abbr" :image="getStudentAvatar(photo)" />

              <span class="text">
                <span class="name">{{ name }}</span>

                <span class="grade">{{ gradeName }}</span>
              </span>
            </div>
          </template>

          <template #option="{ name, gradeName }">
            <div class="s-header__student-option">
              <b class="name">{{ name }}</b>

              <span class="t-tag">{{ gradeName }}</span>
            </div>
          </template>
        </v-select>
      </div>

      <TheHeaderRating v-if="rating" :rating="rating" />

      <nav class="s-header__nav">
        <router-link
          v-if="notifications"
          :to="{ name: 'client-notifications' }"
        >
          <img :src="icons.bell" alt="Иконка уведомление" />

          <i v-if="Number(notifications.notifications) > 0" class="notif">{{
            notifications.notifications
          }}</i>
        </router-link>

        <router-link v-if="calendar" :to="{ name: 'client-calendar' }">
          <img :src="icons.calendar" alt="Иконка календарь" />

          <i v-if="Number(calendar.notifications) > 0" class="notif">
            {{ calendar.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="messages"
          :to="{ name: 'client-messages' }"
          class="ml-20"
        >
          <img :src="icons.message" alt="Иконка сообщение" />

          <i v-if="Number(messages.notifications) > 0" class="notif">
            {{ messages.notifications }}
          </i>
        </router-link>

        <router-link :to="{ name: 'client-invoices' }" class="ml-20">
          <img :src="icons.payments" alt="Иконка Счета" />
        </router-link>

        <router-link :to="{ name: 'client-profile' }" class="wide">
          <img :src="icons.settings" alt="Иконка настроек" />
        </router-link>

        <router-link :to="{ name: 'logout' }" class="wide">
          <img :src="icons.exit" alt="Иконка выход" />
        </router-link>
      </nav>

      <button
        class="s-mobile-menu__toggle"
        :class="{ opened }"
        @click="toggleMobileMenu()"
      >
        <span v-for="i in 3" :key="i"></span>
      </button>
      <transition name="fade">
        <menu v-if="opened" class="s-mobile-menu" :style="mobileMenuTopOffset">
          <div class="block">
            <router-link
              v-for="link in links"
              :key="link.link.name"
              :to="link.link"
              class="link-dark"
            >
              {{ link.label }}
              <i v-if="Number(link.notifications) > 0" class="notif">
                {{ link.notifications }}
              </i>
            </router-link>
          </div>

          <div class="block">
            <router-link :to="{ name: 'client-profile' }" class="link-dark">
              Настройки
            </router-link>

            <router-link :to="{ name: 'logout' }" class="link-dark">
              Выйти
            </router-link>
          </div>
        </menu>
      </transition>
    </div>
  </header>
</template>

<script>
import TheHeaderRating from '@frontend/components/common/TheHeaderRating.vue'
import ElAvatar from '@frontend/ui/ElAvatar.vue'
import { isNull } from 'lodash'
import bell from 'Static/images/@frontend/common-lk/icons/bell.svg'
import calendar from 'Static/images/@frontend/common-lk/icons/calendar.svg'
import exit from 'Static/images/@frontend/common-lk/icons/exit.svg'
import message from 'Static/images/@frontend/common-lk/icons/message.svg'
import payments from 'Static/images/@frontend/common-lk/icons/payments.svg'
import settings from 'Static/images/@frontend/common-lk/icons/settings.svg'
import logo from 'Static/images/@frontend/common-lk/logo.png'

export default {
  name: 'TheHeaderClient',

  components: {
    ElAvatar,
    TheHeaderRating,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },

    fullName: {
      type: String,
      default: '',
    },

    abbreviation: {
      type: String,
      default: '',
    },

    avatar: {
      type: String,
      default: '',
    },

    students: {
      type: Array,
      default: () => [],
    },

    schoolName: {
      type: String,
      default: '',
    },

    currentStudent: {
      type: Object,
      default: null,
    },

    hasEnrolledStudents: {
      type: Boolean,
      required: true,
    },

    hasContractsOrDrafts: {
      type: Boolean,
      required: true,
    },

    noticeText: {
      type: String,
      default: '',
    },

    noticeAdditionalMessage: {
      type: String,
      default: '',
    },

    rating: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    logo,
    icons: {
      calendar,
      settings,
      message,
      payments,
      bell,
      exit,
    },

    opened: false,
    mobileMenuTopOffsetPx: 0,
  }),

  computed: {
    calendar() {
      return this.links.find((i) => i.link.name === 'client-calendar')
    },

    notifications() {
      return this.links.find((i) => i.link.name === 'client-notifications')
    },

    messages() {
      return this.links.find((i) => i.link.name === 'client-messages')
    },

    getAbbr() {
      return (lastName, firstName) =>
        (lastName ? lastName.slice(0, 1).toUpperCase() : '') +
        (firstName ? firstName.slice(0, 1).toUpperCase() : '')
    },

    getName() {
      return (lastName, firstName) =>
        (firstName
          ? firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()
          : '') + (lastName ? ` ${lastName[0].toUpperCase()}.` : '')
    },

    getGradeName() {
      return (gradeName) => (gradeName ? `${gradeName} класс` : '')
    },

    studentsFormatted() {
      if (this.students.length) {
        return this.students.map((student) => {
          const abbr = this.getAbbr(student.lastName, student.firstName)
          const name = this.getName(student.lastName, student.firstName)
          const gradeName = !isNull(student.letter)
            ? this.getGradeName(`${student.grade?.name}-${student.letter}`)
            : this.getGradeName(student.grade?.name)

          return {
            ...student,
            abbr,
            name,
            gradeName,
          }
        })
      }

      return []
    },

    currentStudentFormatted() {
      if (this.currentStudent) {
        const { lastName, firstName, grade, letter } = this.currentStudent
        const abbr = this.getAbbr(lastName, firstName)
        const name = this.getName(lastName, firstName)

        const gradeName = !isNull(letter)
          ? this.getGradeName(`${grade.name}-${letter}`)
          : this.getGradeName(grade.name)

        return {
          ...this.currentStudent,
          abbr,
          name,
          gradeName,
        }
      }

      return {}
    },

    toMain() {
      if (!this.hasEnrolledStudents && !this.hasContractsOrDrafts) {
        return {
          name: 'client-request-new',
        }
      }

      if (!this.hasEnrolledStudents && this.hasContractsOrDrafts) {
        return {
          name: 'client-contracts',
        }
      }

      return '/'
    },

    mobileMenuTopOffset() {
      return {
        top: `${this.mobileMenuTopOffsetPx}px`,
        height: `calc(100vh - ${this.mobileMenuTopOffsetPx}px)`,
      }
    },

    classHeaderClient() {
      return {
        's-header-client': this.students.length,
      }
    },
  },

  watch: {
    /**
     * @description для моб версии: закрывать меню при смене роута
     */
    $route() {
      this.toggleMobileMenu(false)
    },

    noticeText() {
      this.setHeightNoticedMobileMenu()
    },
  },

  mounted() {
    this.setHeightNoticedMobileMenu()
  },

  methods: {
    selectStudent({ id }) {
      this.$emit('select-student', id)
    },

    setHeightNoticedMobileMenu() {
      this.mobileMenuTopOffsetPx = this.$refs.header.clientHeight
    },

    toggleMobileMenu(action) {
      this.opened = action !== undefined ? action : !this.opened
      window.scrollTo(0, 0)
      document.body.style.overflow = this.opened ? 'hidden' : 'unset'
    },

    getStudentAvatar(photo) {
      return photo?.thumbs?.small ?? ''
    },
  },
}
</script>
