import getErrorsList from '@frontend/helpers/getErrorsList'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiDemoStudentRegistration from 'Api/rest/Api/Auth/DemoStudentRegistration'
import ApiOptionsDemoEducationTypes from 'Api/rest/Api/Auth/Options/DemoEducationTypes'
import ApiOptionsDemoGrades from 'Api/rest/Api/Auth/Options/DemoGrades'
import Vue from 'vue'

import { eventBus } from '@/pubsub/bus'

const globalMessageOnRegister = function () {
  eventBus.$emit('frontend.auth.leadCreated')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async register({ state, commit }) {
    const payload = {
      firstName: state.firstName,
      email: state.email,
      grade: state.grade,
      educationType: state.educationType,
      parentPhone: state.parentPhone,
      parentName: state.parentName,
    }

    try {
      const { status } = await ApiDemoStudentRegistration.post(payload)

      if (status === HttpStatusCodes.Created) {
        Vue.toasted.show('Регистрация прошла успешно', {
          type: 'success',
        })

        commit('setInputValue', {
          name: 'isRegistrationSucceed',
          value: true,
        })

        globalMessageOnRegister()
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        Vue.toasted.show(getErrorsList(error), {
          type: 'error',
          keepOnHover: true,
          closeOnSwipe: false,
          duration: Infinity,
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
    }
  },

  async fetchGrades({ commit }, payload) {
    const { data } = await ApiOptionsDemoGrades.get({
      educationType: {
        id: payload.id,
      },
    })

    commit('setInputValue', {
      name: 'grades',
      value: data,
    })
  },

  async fetchFormEducation({ commit }) {
    const { data } = await ApiOptionsDemoEducationTypes.get()

    commit('setInputValue', {
      name: 'educationTypes',
      value: data,
    })
  },
}
