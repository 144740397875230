import { mainRoutes } from '@frontend/routes/student/main'
import { testRoutes } from '@frontend/routes/student/test'
import UserStore from '@frontend/UserStore'

const checkRole = (to, from, next) => {
  if (UserStore.store.getters['common/user/isStudent']) {
    next()
  } else {
    next({
      name: 'login',
    })
  }
}

const routes = [...mainRoutes, ...testRoutes]

routes.forEach((route) => {
  route.beforeEnter = checkRole
})

export default routes
