<template>
  <footer class="s-footer">
    <ul v-if="isPreview" class="list-unstyled s-footer__nav">
      <li>
        <span class="link-dark">Сайт школы</span>
      </li>
      <li>
        <span class="link-dark">Политика конфиденциальности</span>
      </li>
    </ul>

    <ul v-else class="list-unstyled s-footer__nav">
      <li v-if="schoolSite">
        <a
          class="link-dark"
          :href="schoolSite"
          target="_blank"
          rel="noopener noreferrer"
        >
          Сайт школы
        </a>
      </li>

      <!--
        <li> <a class="link-dark" href="">Популярные вопросы</a></li>
        <li> <a class="link-dark" href="">Техническая поддержка</a></li>
      -->

      <li v-if="schoolPolicyFileUrl">
        <a
          class="link-dark"
          :href="schoolPolicyFileUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Политика конфиденциальности
        </a>
      </li>
    </ul>

    <div class="text-secondary">
      <div class="mb-2">
        © IBLS — Интеллектуальные технологии в&nbsp;образовании
      </div>

      <div>id {{ userId }}</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',

  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },

    school: {
      type: Object,
      default: null,
    },

    userId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    schoolSite() {
      return this.school?.site
    },

    schoolPolicyFileUrl() {
      return this.school?.policyFile?.fileUrl
    },
  },
}
</script>
