<template>
  <LoaderFullScreen v-if="isLoading" />

  <LayoutBase v-else :main="main" :noticed="isDemoProfile">
    <template #header>
      <TheHeaderStudent
        :abbreviation="nameAbbreviation"
        :avatar="avatar"
        :full-name="fullNameWithShortLastName"
        :grade="Number(profile.grade)"
        :letter="letter"
        :has-blocking-certifications="hasBlockingCertifications"
        :links="getLinks"
        :notice-additional-message="demoMessage"
        :notice-text="demoAccessTimeText"
        :rating="profile.rating"
        :school-name="schoolName"
      />
    </template>

    <template #aside>
      <TheAside
        :links="getLinks"
        :user-settings="profile.userSettings"
        @toggle-aside="toggleAside"
      />
    </template>

    <template #tabs-nav>
      <TabsNav
        :current-tab="currentTab"
        :tabs="mainTabsFiltered"
        @change="onChangeTabNav"
      />
    </template>

    <template #subjects>
      <Subjects
        :current-tab="currentTab"
        :subjects-additional="getSubjectsAdditional"
        :subjects-optional="getSubjectsOptional"
        :subjects-required="getSubjectsRequired"
      />
    </template>

    <router-view />

    <template #footer>
      <TheFooter :school="profile.school" :user-id="profile.id" />
    </template>

    <BtnUp />
  </LayoutBase>
</template>

<script>
import TabsNav from '@frontend/components/common/TabsNav.vue'
import Subjects from '@frontend/components/student/main/Subjects.vue'
import getEstimatedTime from '@frontend/helpers/getEstimatedTime'
import { mainTabsStudent } from '@frontend/helpers/mainTabs'
import LayoutBase from '@frontend/layouts/common/main/LayoutBase.vue'
import TheAside from '@frontend/layouts/common/TheAside.vue'
import TheFooter from '@frontend/layouts/common/TheFooter.vue'
import TheHeaderStudent from '@frontend/layouts/common/TheHeaderStudent.vue'
import BtnUp from '@frontend/ui/BtnUp.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import dateFormat from 'Helpers/const/dateFormat'
import {
  getDate,
  getEndOfBusinessWeek,
  getStartOfWeek,
} from 'Helpers/dateFormatHelper'
import { isUndefined } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const INTERVAL_TIME = 60000 // 1min
const now = new Date()

export default {
  name: 'StudentMain',

  components: {
    LoaderFullScreen,
    LayoutBase,
    TheHeaderStudent,
    TheAside,
    TheFooter,
    Subjects,
    TabsNav,
    BtnUp,
  },

  data: () => ({
    main: false,
    isLoading: false,
    demoAccessTimeText: '',
    demoTimer: null,
    demoTimerSecondsLeft: 0,
  }),

  computed: {
    ...mapState('student/profile', ['profile']),

    ...mapState('student/main', ['currentTab']),

    avatar() {
      return this.profile.photo?.thumbs?.small ?? ''
    },

    schoolName() {
      return this.profile.school?.name ?? ''
    },

    letter() {
      return this.profile.letter ?? ''
    },

    ...mapGetters('student/profile', [
      'fullNameWithShortLastName',
      'nameAbbreviation',
      'demoAccessTime', // seconds
      'demoMessage',
      'isDemoProfile',
      'educationType',
    ]),

    ...mapGetters('student/subjects', [
      'getSubjectsRequired',
      'getSubjectsOptional',
      'getSubjectsAdditional',
    ]),

    ...mapGetters('student/extramuralCertification', [
      'hasBlockingCertifications',
    ]),

    ...mapGetters('student/main', ['getLinks']),

    mainTabsFiltered() {
      let mainTabs = null

      mainTabs = Object.fromEntries(
        Object.entries(mainTabsStudent).filter(
          ([, value]) =>
            isUndefined(value.educationTypes) ||
            value.educationTypes.includes(this.profile.educationType.id),
        ),
      )

      if (!this.profile.hasPhysiconAccess) {
        mainTabs = Object.fromEntries(
          Object.entries(mainTabs).filter(
            ([, value]) => value.id !== 'physicon',
          ),
        )
      }

      return mainTabs
    },
  },

  watch: {
    /**
     * layout на главной странице отличается от второстепенных страниц
     * по структуре и передается Boolean соответствующий
     */
    $route: {
      immediate: true,
      handler() {
        this.main = this.$route.name === 'student-main'
      },
    },
  },

  created() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.releaseTimerForDemoText()
  },

  methods: {
    ...mapActions({
      fetchUserSettings: 'student/profile/fetchUserSettings',
      updateUserSettings: 'student/profile/updateUserSettings',
      fetchGoals: 'student/goals/fetchGoals',
      fetchSubjects: 'student/subjects/fetchSubjects',
      fetchLessons: 'student/lessons/fetchLessons',
      fetchExtramuralCertifications:
        'student/extramuralCertification/fetchCertifications',
      fetchFamilyCertifications:
        'student/familyCertification/fetchCertifications',
      fetchFullTimeCertifications:
        'student/fullTimeCertification/fetchCertifications',
      homeworkDatesFetch: 'student/homework/homeworkDatesFetch',
      fetchNotifications: 'student/notifications/fetchNotifications',
      fetchJournal: 'student/journal/fetchJournal',
      fetchActiveModules: 'common/module/fetchActiveModules',
    }),

    ...mapMutations('student/main', ['setCurrentTab']),

    /**
     * @returns {Promise<void>}
     */
    async setInitialData() {
      this.isLoading = true

      // TODO: перенести все экшны в один общий экшн
      try {
        const fetchCertifications = async () => {
          if (this.$route.name !== 'certification') {
            if (this.educationType.extramural) {
              await this.fetchExtramuralCertifications()
            }

            if (this.educationType.family) {
              await this.fetchFamilyCertifications()
              await this.fetchExtramuralCertifications()
            }

            if (this.educationType.fullTime) {
              await this.fetchFullTimeCertifications()
            }
          }
        }

        const fetchLessons = async () => {
          if (this.$route.name !== 'lessons') {
            await this.fetchLessons()
          }
        }

        const fetchGoals = async () => {
          if (this.$route.name !== 'goals') {
            await this.fetchGoals()
          }
        }

        const fetchFullTimeData = async () => {
          if (this.educationType.fullTime) {
            const startDate = getDate(
              getStartOfWeek(now),
              dateFormat.DATE_FORMAT_BACKEND,
            )
            const endDate = getDate(
              getEndOfBusinessWeek(now),
              dateFormat.DATE_FORMAT_BACKEND,
            )
            const journalParams = {
              startDate,
              endDate,
            }

            await Promise.all([
              this.homeworkDatesFetch(),
              this.fetchNotifications(),
              this.fetchJournal(journalParams),
            ])
          }
        }

        await this.fetchUserSettings()

        await Promise.all([
          fetchCertifications(),
          fetchFullTimeData(),
          this.fetchSubjects(),
          fetchGoals(),
          fetchLessons(),
          fetchFullTimeData(),
          this.fetchActiveModules(),
        ])

        if (this.isDemoProfile) {
          this.setTimerForDemoText()
        }

        if (this.hasBlockingCertifications) {
          if (this.$route.name !== 'certification') {
            await this.$router.push({
              name: 'certification',
            })
          }
        }
      } finally {
        this.isLoading = false
      }
    },

    /**
     *
     * @param {string|null} estimatedTime
     */
    getTextForDemo(estimatedTime) {
      this.demoAccessTimeText = `Демо-доступ завершится через ${estimatedTime}.`
    },

    tickDemoTimer() {
      this.demoTimerSecondsLeft -= INTERVAL_TIME / 1000
      const estimatedTime = getEstimatedTime(this.demoTimerSecondsLeft)

      if (estimatedTime) {
        this.getTextForDemo(estimatedTime)
      } else {
        let errorText = `Демо-доступ завершен.`

        if (this.demoMessage) {
          errorText += ` ${this.demoMessage}`
        }

        this.$router.push({
          name: 'logout',
        })

        this.$toasted.show(errorText, {
          type: 'error',
          duration: 0,
          action: {
            text: 'OK',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
    },

    setTimerForDemoText() {
      this.demoTimerSecondsLeft = this.demoAccessTime
      this.tickDemoTimer()
      this.demoTimer = setInterval(this.tickDemoTimer, INTERVAL_TIME)
    },

    releaseTimerForDemoText() {
      clearInterval(this.demoTimer)
    },

    /**
     *
     * @param {boolean} isAsideOpen
     */
    toggleAside(isAsideOpen) {
      this.updateUserSettings({
        isAsideOpen,
      })
    },

    onChangeTabNav(tabId) {
      this.setCurrentTab(tabId)
    },
  },
}
</script>
