import ApiNotificationList from 'ApiRest/Api/Notification/List'
import ApiNotification from 'ApiRest/Api/Notification/Read'

export default {
  updateGlobalCounter({ commit, getters }) {
    const count = getters.notificationsUnReadCount

    commit(
      'student/main/notificationSet',
      {
        target: 'notifications',
        count,
      },
      {
        root: true,
      },
    )
  },

  async fetchNotifications({ commit, dispatch }) {
    const { data } = await ApiNotificationList.get()

    commit('notificationsSet', data)
    dispatch('updateGlobalCounter')
  },

  async readNotification({ commit, dispatch }, notificationId) {
    const { data } = await ApiNotification.patch(notificationId)

    commit('readNotificationSet', data)
    dispatch('updateGlobalCounter')
  },
}
