import { getDefaultParamForClient } from '@frontend/getDefaultParam'
import { ApiSupportQuestion } from 'ApiRest/Api/Support/Question'
import { ApiSupportQuestionComment } from 'ApiRest/Api/Support/Question/Comment'
import { ApiSupportQuestionInWorkCount } from 'ApiRest/Api/Support/Question/InWorkCount'
import { ApiSupportQuestionList } from 'ApiRest/Api/Support/Question/List'
import { cloneDeep } from 'lodash'

export default {
  async fetchQuestions({ commit }) {
    const { data } = await ApiSupportQuestionList.get(
      getDefaultParamForClient(),
    )

    commit('setQuestions', data)
  },

  async fetchTheQuestion({ commit, getters }) {
    const { data } = await ApiSupportQuestion.get(
      getters.questionId,
      getDefaultParamForClient(),
    )

    commit('setTheQuestion', data)
  },

  async updateTheQuestion({ state, getters }) {
    await ApiSupportQuestion.patch(
      getters.questionId,
      state.theQuestion,
      getDefaultParamForClient(),
    )
  },

  async addNewComment({ state, getters }) {
    await ApiSupportQuestionComment.post(
      getters.questionId,
      state.newComment,
      getDefaultParamForClient(),
    )
  },

  async createQuestion({ state, rootGetters }) {
    const isClient = rootGetters['common/user/isClient']
    const currentStudentId = rootGetters['client/students/currentStudentId']

    const payload = cloneDeep(state.newQuestion)

    if (isClient && currentStudentId) {
      payload.student = {
        id: currentStudentId,
      }
    }

    await ApiSupportQuestion.post(payload, getDefaultParamForClient())
  },

  async fetchQuestionsInWorkCount({ commit }) {
    const { data } = await ApiSupportQuestionInWorkCount.get(
      getDefaultParamForClient(),
    )

    commit('setQuestionsInWorkCount', data)
  },
}
