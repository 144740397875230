import { getDefaultParamForClient } from '@frontend/getDefaultParam'
import { ApiSupportIdeaComment } from 'ApiRest/Api/Support/Idea/Comment'
import { ApiSupportIdea } from 'ApiRest/Api/Support/Idea/Idea'
import { ApiSupportIdeaLike } from 'ApiRest/Api/Support/Idea/Like'
import { ApiSupportIdeasList } from 'ApiRest/Api/Support/Idea/List'
import { ApiSupportIdeaOptionStatus } from 'ApiRest/Api/Support/Idea/Option/Status'
import Constants from 'Constants'

export default {
  async fetchIdeas({ commit, state }) {
    const parameters = {
      expand: 'status',
      rowsPerPage: 200,
      sort: '-voteCount',
      status: {
        id: state.optionsSelected.status.id,
      },
      ...getDefaultParamForClient(),
    }

    const { data } = await ApiSupportIdeasList.get(parameters)

    commit('setIdeas', data)
  },

  async fetchTheIdea({ commit, getters }) {
    const { data } = await ApiSupportIdea.get(
      getters.ideaId,
      getDefaultParamForClient(),
    )

    commit('setTheIdea', data)
  },

  async updateTheIdea({ getters, state }) {
    await ApiSupportIdea.patch(
      getters.ideaId,
      state.theIdea,
      getDefaultParamForClient(),
    )
  },

  async createIdea({ state }) {
    await ApiSupportIdea.post(state.newIdea, getDefaultParamForClient())
  },

  async addNewComment({ getters, state }) {
    await ApiSupportIdeaComment.post(
      getters.ideaId,
      state.newComment,
      getDefaultParamForClient(),
    )
  },

  async fetchStatuses({ commit, state }) {
    const { data } = await ApiSupportIdeaOptionStatus.get(
      getDefaultParamForClient(),
    )

    commit('setOption', {
      path: 'statuses',
      value: data,
    })

    if (state.optionsSelected.status === null) {
      const defaultOption = state.options.statuses.find(
        (status) => status.id === Constants.supportIdeaStatus.POPULAR,
      )

      commit('setOptionSelected', {
        path: 'status',
        value: defaultOption,
      })
    }
  },

  async voteForIdea({ getters }, ideaId) {
    await ApiSupportIdeaLike.post(
      ideaId ?? getters.ideaId,
      getDefaultParamForClient(),
    )
  },
}
