export default () => ({
  invoices: [
    {
      amount: '',
      createdDate: '',
      id: null,
      number: '',
      promocode: null,

      service: {
        price: null,
        id: null,
        name: '',
      },

      status: {
        id: null,
        name: '',
      },

      student: {
        id: null,
        name: '',
      },

      url: '',
    },
  ],

  invoice: {
    id: null,
    number: null,
    amount: null,
    url: null,
    createdDate: null,
    status: null,
    service: null,
    student: null,

    promocode: {
      id: null,
      code: null,
      bestBefore: null,
      status: null,

      group: {
        id: null,
        name: null,
        bestBefore: null,
        initialGeneratedCount: null,
        discount: null,
        newPromocodesCount: null,
        usedPromocodesCount: null,
        expiredPromocodesCount: null,
        discountType: null,
      },
    },
  },

  paymentUrl: '',
})
