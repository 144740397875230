<template>
  <header class="s-header s-header-client mb-0">
    <div class="s-logo s-header__logo">
      <h2 class="s-school-name">{{ schoolName }}</h2>

      <router-link :to="{ name: 'login' }">
        <img :src="logo" alt="IBLS логотип" />
      </router-link>
    </div>
  </header>
</template>

<script>
import logo from 'Static/images/@frontend/common-lk/logo.png'

export default {
  name: 'TheHeaderGuest',

  props: {
    schoolName: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    logo,
  }),
}
</script>
