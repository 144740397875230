import getErrorsListNames from '@frontend/helpers/getErrorsListNames'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiFormEnrollmentApplication from 'ApiRest/Api/Form/EnrollmentApplication'
import Option from 'ApiRest/Api/Option'
import SchoolGrades from 'ApiRest/Api/School/Grades'
import Vue from 'vue'

export default {
  async fetchGrades({ commit, state }) {
    const params = {
      withKindergarten: 1,
    }

    const { schoolId } = state.formData

    await SchoolGrades.get(schoolId, params).then(({ data }) => {
      commit('setGrades', data)
    })
  },

  async fetchMoscowRegistrationTypes({ commit }) {
    await Option.getMoscowRegistrationTypes().then(({ data }) => {
      commit('setMoscowRegistrationTypes', data)
    })
  },

  async fetchKinshipDegrees({ commit }) {
    await Option.getKinshipDegrees().then(({ data }) => {
      commit('setKinshipDegrees', data)
    })
  },

  async saveForm({ state }) {
    try {
      const { status } = await ApiFormEnrollmentApplication.post(state.formData)
      const { Ok, Created } = HttpStatusCodes

      if (status === Ok || status === Created) {
        Vue.toasted.show(
          'Ваша заявка отправлена на рассмотрение, наш менеджер свяжется с Вами',
          {
            type: 'success',
          },
        )
      }
    } catch (error) {
      Vue.toasted.show(getErrorsListNames(error, state.meta.formData), {
        type: 'error',
        keepOnHover: true,
        closeOnSwipe: false,
        duration: Infinity,
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })
    }
  },
}
