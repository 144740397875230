<template>
  <header
    ref="header"
    class="s-header-wrapper"
    :class="{ noticed: noticeText }"
  >
    <div v-if="noticeText" class="s-header__notice">
      <span>
        {{ noticeText }}

        <span class="notice-text--thin">{{ noticeAdditionalMessage }}</span>
      </span>
    </div>

    <div class="s-header">
      <div class="s-logo s-header__logo">
        <h2 class="s-school-name">{{ schoolName }}</h2>

        <router-link :to="routeMain">
          <img :src="logo" alt="IBLS логотип" />
        </router-link>
      </div>

      <div class="s-header__profile">
        <ElAvatar :abbreviation="abbreviation" :image="avatar" />

        <div class="text">
          <span class="name">{{ fullName }}</span>

          <span v-if="isShownGradeName" class="grade">
            {{ gradeName }}
          </span>
        </div>

        <v-select
          v-if="isShownGradeSelect"
          class="v-select-student ml-3"
          label="abbr"
          :clearable="false"
          :searchable="false"
          :options="gradeOptions"
          :value="gradeOptionSelected"
          @input="onInputGrade"
        >
          <template #selected-option="option">
            <div class="s-header__student">
              <div class="text">
                <span class="name">Выбрать класс</span>
                <span class="grade">{{ option.gradeName }}</span>
              </div>
            </div>
          </template>

          <template #option="option">
            <div class="s-header__student-option">
              <b class="name">{{ option.gradeName }}</b>
            </div>
          </template>
        </v-select>
      </div>

      <TheHeaderRating v-if="rating" :rating="rating" />

      <nav class="s-header__nav">
        <router-link v-if="linkNotifications" :to="{ name: 'notifications' }">
          <img :src="icons.bell" alt="Иконка уведомление" />

          <i v-if="Number(linkNotifications.notifications) > 0" class="notif">
            {{ linkNotifications.notifications }}
          </i>
        </router-link>

        <router-link v-if="linkCalendar" :to="{ name: 'calendar' }">
          <img :src="icons.calendar" alt="Иконка календарь" />

          <i v-if="Number(linkCalendar.notifications) > 0" class="notif">
            {{ linkCalendar.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="linkMessages"
          :to="{ name: 'messages' }"
          class="ml-20"
        >
          <img :src="icons.message" alt="Иконка сообщение" />

          <i v-if="Number(linkMessages.notifications) > 0" class="notif">
            {{ linkMessages.notifications }}
          </i>
        </router-link>

        <router-link v-if="canAccessProfile" :to="{ name: 'student-profile' }">
          <img :src="icons.settings" alt="Иконка настроек" />
        </router-link>

        <router-link :to="{ name: 'logout' }" class="wide">
          <img :src="icons.exit" alt="Иконка выход" />
        </router-link>
      </nav>

      <button
        class="s-mobile-menu__toggle"
        :class="{ opened: mobileMenu.isOpen }"
        @click="toggleMobileMenu"
      >
        <span v-for="i in 3" :key="i"></span>
      </button>

      <transition name="fade">
        <div
          v-if="mobileMenu.isOpen"
          class="s-mobile-menu"
          :style="mobileMenuTopOffset"
        >
          <div class="block">
            <router-link
              v-for="link in links"
              :key="link.link.name"
              :to="link.link"
              class="link-dark"
            >
              {{ link.label }}

              <i v-if="Number(link.notifications) > 0" class="notif">
                {{ link.notifications }}
              </i>
            </router-link>
          </div>

          <div class="block">
            <router-link
              v-if="canAccessProfile"
              :to="{ name: 'student-profile' }"
              class="link-dark"
            >
              Настройки
            </router-link>

            <router-link :to="{ name: 'logout' }" class="link-dark">
              Выйти
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import TheHeaderRating from '@frontend/components/common/TheHeaderRating.vue'
import ElAvatar from '@frontend/ui/ElAvatar.vue'
import bell from 'Static/images/@frontend/common-lk/icons/bell.svg'
import calendar from 'Static/images/@frontend/common-lk/icons/calendar.svg'
import exit from 'Static/images/@frontend/common-lk/icons/exit.svg'
import message from 'Static/images/@frontend/common-lk/icons/message.svg'
import settings from 'Static/images/@frontend/common-lk/icons/settings.svg'
import logo from 'Static/images/@frontend/common-lk/logo.png'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'TheHeaderStudent',

  components: {
    ElAvatar,
    TheHeaderRating,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },

    fullName: {
      type: String,
      default: '',
    },

    abbreviation: {
      type: String,
      default: '',
    },

    avatar: {
      type: String,
      default: '',
    },

    grade: {
      type: Number,
      default: null,
    },

    letter: {
      type: String,
      default: '',
    },

    schoolName: {
      type: String,
      default: '',
    },

    hasBlockingCertifications: {
      type: Boolean,
      required: true,
    },

    noticeText: {
      type: String,
      default: '',
    },

    noticeAdditionalMessage: {
      type: String,
      default: '',
    },

    rating: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      logo,

      icons: {
        calendar,
        settings,
        message,
        bell,
        exit,
      },

      mobileMenu: {
        isOpen: false,
        topOffset: 0,
      },

      gradeOptionSelected: null,
    }
  },

  computed: {
    ...mapState('student/profile', ['profile']),

    ...mapGetters('common/user', ['isDemoUser']),

    hasGrade() {
      return Boolean(this.grade)
    },

    isEducont() {
      return Boolean(this.profile?.school?.isEducont)
    },

    isShownGradeName() {
      return this.hasGrade && !this.isEducont
    },

    isShownGradeSelect() {
      return this.isEducont
    },

    gradeName() {
      return `${this.grade}${this.letter ? `-${this.letter}` : ''} класс`
    },

    grades() {
      return this.profile?.school.grades ?? []
    },

    gradeOptions() {
      return this.grades.map((grade) => this.createGradeOption(grade))
    },

    linkCalendar() {
      return this.links.find((link) => link.link.name === 'calendar')
    },

    linkNotifications() {
      return this.links.find((link) => link.link.name === 'notifications')
    },

    linkMessages() {
      return this.links.find((link) => link.link.name === 'messages')
    },

    routeMain() {
      if (this.hasBlockingCertifications) {
        return {
          name: 'certification',
        }
      }

      return '/'
    },

    /**
     * @returns {boolean}
     */
    canAccessProfile() {
      return !this.isDemoUser && !this.isEducont
    },

    mobileMenuTopOffset() {
      return {
        top: `${this.mobileMenu.topOffset}px`,
        height: `calc(100vh - ${this.mobileMenu.topOffset}px)`,
      }
    },
  },

  watch: {
    $route() {
      this.closeMobileMenu()
    },

    noticeText() {
      this.setHeightNoticedMobileMenu()
    },

    'mobileMenu.isOpen': function () {
      this.onChangeMobileMenu()
    },
  },

  mounted() {
    this.setHeightNoticedMobileMenu()
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('student/profile', ['updateGrade']),

    init() {
      this.initGradeSelectedOption()
    },

    initGradeSelectedOption() {
      this.setGradeOptionSelected(this.profile?.grade)
    },

    setHeightNoticedMobileMenu() {
      this.mobileMenu.topOffset = this.$refs.header.offsetHeight
    },

    setGradeOptionSelected(optionName) {
      const grade = this.grades.find(({ name }) => name === optionName)

      if (grade) {
        this.gradeOptionSelected = this.createGradeOption(grade)
      }
    },

    onChangeMobileMenu() {
      this.updateBodyOverflow()
      this.scrollToTop()
    },

    async onInputGrade(option) {
      const optionPrev = this.gradeOptionSelected

      this.setGradeOptionSelected(option.name)

      try {
        await this.updateGrade(option.id)
        this.$router.go(0)
      } catch (error) {
        this.setGradeOptionSelected(optionPrev.name)

        this.$toasted.error('Не удалось сменить класс', {
          duration: 3500,
        })
      }
    },

    createGradeOption(grade) {
      return {
        ...grade,
        abbr: grade.id,
        gradeName: `${grade.name} класс`,
      }
    },

    updateBodyOverflow() {
      document.body.style.overflow = this.mobileMenu.isOpen ? 'hidden' : 'unset'
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    },

    toggleMobileMenu() {
      this.mobileMenu.isOpen = !this.mobileMenu.isOpen
    },

    closeMobileMenu() {
      this.mobileMenu.isOpen = false
    },
  },
}
</script>
