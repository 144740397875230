var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.avatar, _vm.sizeClass, _vm.shapeClass] },
    [
      _vm.image
        ? _c("img", { attrs: { src: _vm.image, alt: "Аватар" } })
        : _c("div", { class: _vm.$style.abbreviation }, [
            _vm._v(_vm._s(_vm.abbreviation)),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }