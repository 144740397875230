import ApiBase from 'ApiRest/ApiBase'

export const ApiEducontLessonFeedback = new (class extends ApiBase {
  /**
   * @param {number} lessonId
   * @param {string} message
   * @returns {Promise}
   */
  post(lessonId, message) {
    const url = `/educont/lesson/${lessonId}/feedback`
    const payload = {
      message,
    }

    return this._POST(url, payload)
  }
})()
