import Constants from 'Constants'

const descriptions = [
  {
    id: Constants.familyEducationCertRequestSubmissionType.PERSONAL,
    description: `<p>Вам необходимо приехать по адресу:</p><p class="font-weight-medium mb-3">Москва, ул. Бутлерова, д. 17, бизнес-центр «Neo Geo», корпус A, офис 22 (отделение поддержки семейного обучения).</p><p>При себе иметь:</p><ul class="pl-3"><li>Паспорт</li><li>Свидетельство о рождении</li><li>Заявление на аттестацию</li><li>Договор</li></ul>`,
  },
  {
    id: Constants.familyEducationCertRequestSubmissionType.VIA_POST,
    description: `<p>Вам необходимо отправить нам письмо с оригиналами заявления и договором по адресу:</p><p class="font-weight-medium mb-3">117485, Москва, ул. Профсоюзная, дом 92</p><p>Кому:</p><p class="font-weight-medium mb-3">ЧОУ СОШ «Наши Пенаты», СО.</p><div class="text-attention mb-3"><p>Просим обратить внимание, что если письмо придет позже срока подачи документов в информационную систему Департамента образования г. Москвы, вы не сможете пройти аттестацию в выбранный период.</p></div><p>Вам необходимо также для зачисления в контингент школы загрузить в личный кабинет скан или фото документов:</p><ul class="pl-3"><li>Паспорт</li><li>Свидетельство о рождении</li></ul>`,
  },
]

export default {
  getAllStepsValid(state) {
    return Object.values(state.validStatus).every((status) => status === true)
  },

  getSchedules(state) {
    return state.theRequestIsAvailable?.schedules
  },

  getSubmissionTypes(state) {
    return state.submissionTypes.map((type, i) => ({
      ...type,
      description: descriptions[i].description,
    }))
  },

  getMainRequiredTests(state) {
    return state.theRequestIsAvailable?.tests.filter(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.MAIN_REQUIRED,
    )
  },

  getRequiredTests(state) {
    return state.theRequestIsAvailable?.tests
      .filter(
        (test) =>
          test.group.id ===
          Constants.familyEducationCertificationTestGroup.REQUIRED,
      )
      .sort((a, b) => a.subject.id - b.subject.id)
  },

  getOptionalTests(state) {
    return state.theRequestIsAvailable?.tests
      .filter(
        (test) =>
          test.group.id ===
          Constants.familyEducationCertificationTestGroup.OPTIONAL,
      )
      .sort((a, b) => a.subject.id - b.subject.id)
  },

  isRequestAvailable(state) {
    return Boolean(state.theRequestIsAvailable?.schedules?.length)
  },

  isRequestAlreadyApplied(state) {
    return Boolean(state.theRequest?.status)
  },

  getRequestStatus(state) {
    const {
      AWAITING_DOCUMENTS,
      TRANSIT_DOCUMENTS,
      RETURNED,
      DOCUMENTS_RECEIVED,
      READY_TO_GO,
      STARTED,
      DRAFT,
    } = Constants.familyEducationCertRequestStatus

    const statusId = state.theRequest?.status?.id

    return {
      canEditSendData:
        statusId === TRANSIT_DOCUMENTS || statusId === AWAITING_DOCUMENTS,
      returned: statusId === RETURNED,
      documentsReceived: statusId === DOCUMENTS_RECEIVED,
      readyToGo: statusId === READY_TO_GO,
      started: statusId === STARTED,
      draft: statusId === DRAFT,
      canDownloadDocs:
        statusId === TRANSIT_DOCUMENTS ||
        statusId === AWAITING_DOCUMENTS ||
        statusId === RETURNED ||
        statusId === DRAFT,
    }
  },
}
