import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import { ApiAccountStudentEducontStream } from 'ApiRest/Api/Account/Student/Educont/Stream'
import ApiPasswordChange from 'ApiRest/Api/Account/Student/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Student/Profile'
import ApiSchool from 'ApiRest/Api/Account/Student/School'
import ApiNotification from 'ApiRest/Api/Notification'
import ApiNotificationEmail from 'ApiRest/Api/Notification/Email'
import ApiNotificationTelegram from 'ApiRest/Api/Notification/Telegram'
import ApiUserSettings from 'ApiRest/Api/UserSettings'
import Vue from 'vue'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  fetchProfile({ commit, dispatch }) {
    return ApiProfile.get().then(({ data }) => {
      commit('setProfile', data)
      commit(
        'common/user/setUserValue',
        {
          key: 'isDemo',
          value: data.isDemo,
        },
        {
          root: true,
        },
      )

      commit(
        'common/user/setUserValue',
        {
          key: 'email',
          value: data.email,
        },
        {
          root: true,
        },
      )

      if (data.school?.id) {
        dispatch('fetchSchool', data.school.id)
      }
    })
  },

  updateProfile({ state }) {
    return ApiProfile.patch(state.profile)
  },

  fetchNotifications({ commit }) {
    return ApiNotification.get().then(({ data }) => {
      commit('setNotifications', data)
    })
  },

  async fetchTelegramNotifications({ state, commit }) {
    return ApiNotificationTelegram.get().then(({ data }) => {
      commit('setNotifications', {
        ...state.notifications,
        telegram: data,
      })
    })
  },

  sendTelegramChatId({ state, commit }, { chatId }) {
    const body = Number(chatId)

    return ApiNotificationTelegram.post(body).then(
      ({ data: { success, payload } }) => {
        if (payload) {
          commit('setNotifications', {
            ...state.notifications,
            telegram: {
              ...state.notifications.telegram,
              payload,
            },
          })
        }

        return success
      },
    )
  },

  removeTelegramNotifications() {
    return ApiNotificationTelegram.delete()
  },

  sendEmail() {
    return ApiNotificationEmail.post()
  },

  removeEmailNotifications() {
    return ApiNotificationEmail.delete()
  },

  fetchSchool({ commit }, payload) {
    return ApiSchool.get(payload).then(({ data }) => {
      commit('setSchool', data)
    })
  },

  updateProfilePasswords(context, payload) {
    // TODO: переместить вызов toasted в компонент
    return ApiPasswordChange.patch(payload)
      .then(() => {
        Vue.toasted.show('Пароль успешно изменен', {
          type: 'success',
        })
      })
      .catch((error) => {
        let message = 'Возникла ошибка при сохранении. Попробуйте еще раз'

        if (error.status === HttpStatusCodes.UnprocessableEntity) {
          message = 'Старый пароль введён неверно'
        }

        Vue.toasted.show(message, {
          type: 'error',
        })

        throw error
      })
  },

  fetchUserSettings({ commit }) {
    const body = {
      name: 'commonSettings',
    }

    return ApiUserSettings.get(body).then(({ data }) => {
      commit('setUserSettings', data)
    })
  },

  updateUserSettings({ state, commit }, payload) {
    const body = {
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    }

    return ApiUserSettings.put(body).then(({ data }) => {
      commit('setUserSettings', data)
    })
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },

  async updateGrade(context, gradeId) {
    await ApiAccountStudentEducontStream.patch(gradeId)
  },
}
