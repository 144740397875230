<template>
  <div :class="[$style.avatar, sizeClass, shapeClass]">
    <img v-if="image" :src="image" alt="Аватар" />
    <div v-else :class="$style.abbreviation">{{ abbreviation }}</div>
  </div>
</template>

<script>
const avatarSizes = ['original', 'medium', 'small']

const avatarShapes = ['circle', 'rounded', 'square']

export default {
  name: 'ElAvatar',

  props: {
    abbreviation: {
      type: String,
      default: '',
    },

    image: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      validator: (prop) => avatarSizes.includes(prop),
      default: 'small',
    },

    shape: {
      type: String,
      validator: (prop) => avatarShapes.includes(prop),
      default: 'rounded',
    },
  },

  computed: {
    /**
     * @returns {string}
     */
    shapeClass() {
      switch (this.shape) {
        case 'circle':
          return 'rounded-circle'

        case 'rounded':
          return 'rounded'

        default:
          return ''
      }
    },

    /**
     * @returns {string}
     */
    sizeClass() {
      switch (this.size) {
        case 'original':
          return this.$style.sizeOriginal

        case 'medium':
          return this.$style.sizeMd

        default:
          return this.$style.sizeSm
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.avatar {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: $gray-200;

  img {
    max-width: 100%;
    height: auto;
  }
}

.abbreviation {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: $dark;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.size {
  &Original {
    $size: 150px;

    width: $size;
    height: $size;

    @include media-breakpoint-up(sm) {
      $size: 300px;

      width: $size;
      height: $size;
    }
  }

  &Md {
    $size: 64px;

    width: $size;
    height: $size;

    @include media-breakpoint-up(sm) {
      $size: 80px;

      width: $size;
      height: $size;
    }
  }

  &Sm {
    $size: 80px;

    width: 40px;
    height: 40px;

    @include media-breakpoint-up(sm) {
      $size: 48px;

      width: $size;
      height: $size;
    }
  }
}
</style>
