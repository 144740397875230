import { common } from '@frontend/store/common/store'
import { helper } from '@frontend/store/helper/store'
import Vuex from 'vuex'

import auth from './store/auth/store'
import client from './store/client/store'
import guest from './store/guest/store'
import student from './store/student/store'

export default new (class Store {
  constructor() {
    this.store = null
  }

  init() {
    this.store = new Vuex.Store({
      strict: process.iblsEnv.NODE_ENV !== 'production',

      modules: {
        auth,
        client,
        student,
        common,
        helper,
        guest,
      },

      state: {
        isGlobalLoading: false,
      },

      mutations: {
        setIsGlobalLoading: (state, payload = false) => {
          state.isGlobalLoading = payload
        },
      },

      actions: {
        resetGlobalState() {
          const mutationNames = ['RESET_STATE', 'resetState']

          const resetStateMutations = (mutation) =>
            mutationNames.some((name) => mutation.includes(name))

          Object.keys(this._mutations)
            .filter(resetStateMutations)
            .forEach((mutation) => {
              this.commit(mutation, null, {
                root: true,
              })
            })
        },
      },
    })

    return this.store
  }
})()
