// Сохранение настроек доменов в window
import 'Helpers/domains'
import '@/plugins/echarts'

import UserApp from '@frontend/App.vue'
import UserRouter from '@frontend/UserRouter'
import UserStore from '@frontend/UserStore'
import SentryHelper from 'Helpers/SentryHelper'
import { trackersInit } from 'Helpers/trackers'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueDeprecate from 'vue-deprecate'
import VModal from 'vue-js-modal'
import { VueMasonryPlugin } from 'vue-masonry'
import VueMeta from 'vue-meta'
import Router from 'vue-router'
import vSelect from 'vue-select'
import VueTippy from 'vue-tippy'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'

import { pubSubInit } from '@/pubsub/subscriber'

trackersInit()
pubSubInit()
SentryHelper.init()

Vue.config.productionTip = false

if (process.iblsEnv.NODE_ENV === 'production') {
  Vue.config.silent = true
  Vue.config.debug = false
  Vue.config.devtools = false
}

const { host } = window.location
const isDomainUserMobile = host === window.domains.mobile

let app

const tippyOptions = {
  onShow: (options) => Boolean(options.props.content),
}

Vue.use(Router)
Vue.use(Vuex)
Vue.use(VueTippy, tippyOptions)
Vue.use(VueMeta)
Vue.use(VCalendar)
Vue.use(VueDeprecate)
Vue.use(Vuelidate)

const createAppUser = () => {
  const router = UserRouter.init()
  const store = UserStore.init()

  Vue.use(VModal)
  Vue.use(VueMasonryPlugin)
  Vue.use(Toasted, {
    position: 'bottom-center',
    duration: 2000,
  })

  Vue.component('VSelect', vSelect)

  Vue.prototype.$isMobile = isDomainUserMobile

  app = new Vue({
    router,
    store,
    render: (h) => h(UserApp),
  })
}

createAppUser()

if (!app) {
  throw new Error(`App is not initialized!`)
}

const preload = document.querySelector('#preload')

if (preload) {
  preload.remove()
}

app.$mount('#app')
