import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCurrentTab(state, tab = '') {
    state.currentTab = tab
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.target
   * @param {number} payload.count
   */
  notificationSet(state, { target, count = 0 }) {
    state.links.find((i) => i.link.name === target).notifications = count
  },
}
