import createState from '@frontend/store/client/contracts/state'
import enrollmentStatusHelper from 'Helpers/EnrollmentStatusHelper'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setContracts(state, payload) {
    state.contracts = enrollmentStatusHelper.transformStatuses(payload)
  },

  setDrafts(state, payload) {
    state.drafts = payload
  },
}
