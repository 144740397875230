export default () => ({
  legalRepresentativeTypes: [],
  submissionTypes: [],
  citizenships: [],
  countries: [],

  theRequestIsAvailable: {
    id: null,
    name: '',
    schedules: [],
    tests: [],
  },

  theRequest: {
    id: null,
    applicationDocument: null,
    contractDocument: null,
    clientLastName: null,
    clientFirstName: null,
    clientMiddleName: null,
    clientBirthDate: '',
    citizenship: null,
    country: null,
    passSeries: null,
    passNumber: null,
    passIssuedBy: null,
    passIssuedByCode: null,
    passDateIssue: null,
    passValidUntil: null,
    regAddress: null,
    postalAddress: null,
    studentLastName: null,
    studentFirstName: null,
    studentMiddleName: null,
    studentBirthDate: null,
    birthCertificateCitizenship: null,
    birthCertificateSeries: null,
    birthCertificateNumber: null,
    studentRegAddress: '',
    studentRegAddressConfirmDocFile: null,
    birthCertificateDateIssue: null,
    trackingNumber: null,
    logisticsCompanyName: null,
    status: null,
    client: null,
    student: null,
    certification: null,
    schedule: null,
    legalRepresentativeType: null,
    submissionType: null,
    clientPassportMainPageFile: null,
    studentBirthCertificateFile: null,
    studentPassportMainPageFile: null,
    tests: [],
  },

  steps: [],

  // Статусы валидности данных в компонентах
  validStatus: {
    DateData: true,
    SubjectData: true,
    ApplicationData: true,
    DocumentsData: true,
    SendData: true,
  },

  isEachInitialValueApplied: false,
})
