import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiChangePassword from 'ApiRest/Api/User/ChangePassword'
import ApiPasswordRecoveryConfirm from 'ApiRest/Api/User/PasswordRecoveryConfirm'
import ApiPasswordRecoveryToken from 'ApiRest/Api/User/PasswordRecoveryToken'
import Vue from 'vue'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  setStage({ commit }, stage) {
    commit('setStage', stage)
  },

  async resetStage1({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    const payload = {
      email: state.email,
    }

    try {
      const { status } = await ApiPasswordRecoveryToken.patch(payload)

      if (status === HttpStatusCodes.Ok) {
        commit('setStage', 2)
      }
    } catch (error) {
      const status = error?.response?.status
      const message = error?.response?.data?.message

      if (status === HttpStatusCodes.NotFound) {
        Vue.toasted.show('Введенный e-mail не найден', {
          type: 'error',
        })
      }

      if (message) {
        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    }

    commit('setIsLoading', false)

    return true
  },

  async resetStage3CheckToken({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    try {
      await ApiPasswordRecoveryConfirm.patch({
        code: state.code,
      })
    } catch (error) {
      const status = error?.response?.status

      if (status === HttpStatusCodes.NotFound) {
        commit('setInputValue', {
          name: 'code',
        })

        Vue.toasted.show('Данная ссылка устарела', {
          type: 'error',
        })

        UserRouter.router.push({
          name: 'password-reset',
        })
      }
    }

    commit('setIsLoading', false)

    return true
  },

  async resetStage3({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    const payload = {
      code: state.code,
      newPassword: state.password,
      confirmPassword: state.passwordRepeat,
    }

    try {
      await ApiChangePassword.patch(payload)

      Vue.toasted.show('Пароль успешно сменен', {
        type: 'success',
      })

      UserRouter.router.push({
        name: 'login',
      })
    } catch (error) {
      const message = error?.response?.data?.message

      if (message) {
        Vue.toasted.show(message, {
          type: 'error',
        })
      }
    }

    commit('setIsLoading', false)

    return true
  },
}
